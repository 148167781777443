import React, { useState, forwardRef, useImperativeHandle } from 'react';
import "../payment-verification/payment-verification.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FaCheckCircle } from "react-icons/fa";

const PaymentVerificationStepper5 = forwardRef((props, ref) => {
    const {
        setLoading,
        stepper,
        setStepper,
        setStepperValidation,
    } = props;

    useImperativeHandle(ref, () => ({
        updatePassword
    }));

    let [password, setPassword] = useState({
        password: '',
        retryPassword: ''
    });

    let [passwordValidation, setPasswordValidation] = useState({
        length: false,
        special: false,
        match: false
    });

    const handlePasswordChange = (fieldName, value) => {
        setPassword(prevPassword => {
            const updatedPassword = {
                ...prevPassword,
                [fieldName]: value
            };

            let length = false

            if (updatedPassword['password'].length >= 8) {
                length = true
            }

            const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

            let special = true

            if (!specialCharacters.test(updatedPassword['password'])) {
                special = false
            }

            let match = false

            if (updatedPassword['password'] === updatedPassword['retryPassword']) {
                match = true
            }

            setPasswordValidation(prevPWV => {
                const updatedPWV = {
                    ...prevPWV,
                    ['length']: length,
                    ['special']: special,
                    ['match']: match
                };

                return updatedPWV;
            });

            setStepperValidation(prevState => ({
                ...prevState,
                5: length && special && match
            }));

            return updatedPassword;
        });
    };

    const updatePassword = async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_API + 'updatePassword', {
            method: 'POST',
            body: JSON.stringify({
                password: password['password']
            }),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message'] === "Password updated successfully") {
                localStorage.setItem('status', 6);
                setStepper(stepper + 1)
                window.scrollTo(0, 0);
            }
        }

        setLoading(false);
    };

    return (
        <div className="inner-content">
            <div className="main-title form-title">قم بتعيين كلمة مرور جديدة خاصة بك</div>

            <form autoComplete="off" className="mb-4" noValidate>
                <div className='verify-phone'>

                    <label className="main-label">كلمة المرور</label>
                    <input className="main-input" tabIndex="1" type="password" placeholder="كلمة المرور" onChange={(e) => handlePasswordChange('password', e.target.value)} />

                    <label className="main-label">إعادة كلمة المرور</label>
                    <input className="main-input" tabIndex="1" type="password" placeholder="إعادة كلمة المرور" onChange={(e) => handlePasswordChange('retryPassword', e.target.value)} />

                    <div className='errors'>
                        <div className='error-msg'>
                            {passwordValidation['length'] ? <FaCheckCircle style={{ color: 'green', width: '16px', height: '16px', flex: 'none' }} /> :
                                <FontAwesomeIcon fontSize={16} color='#126ed0' icon={faCircleExclamation} style={{ flex: 'none' }} />}
                            <span className='msg'> يجب أن تتكون كلمة المرور من 8 أحرف على الأقل. </span>
                        </div>

                        <div className='error-msg'>
                            {passwordValidation['special'] ? <FaCheckCircle style={{ color: 'green', width: '16px', height: '16px', flex: 'none' }} /> :
                                <FontAwesomeIcon fontSize={16} color='#126ed0' icon={faCircleExclamation} style={{ flex: 'none' }} />}
                            <span className='msg'> يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل. (!,@,#) </span>
                        </div>

                        <div className='error-msg'>
                            {passwordValidation['match'] ? <FaCheckCircle style={{ color: 'green', width: '16px', height: '16px', flex: 'none' }} /> :
                                <FontAwesomeIcon fontSize={16} color='#126ed0' icon={faCircleExclamation} style={{ flex: 'none' }} />}
                            <span className='msg'> كلمة المرور يجب ان تتطابق. </span>
                        </div>



                    </div>

                </div>

            </form>
        </div>
    );
});

export default PaymentVerificationStepper5;
