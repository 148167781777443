import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney, faBars, faTimes, faVideo, faQuestion, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './course.css';
import courseImage from '../../assets/img/course.avif';

import toast, { Toaster } from "react-hot-toast"
import Loading from '../../components/loading';

import Header from "../../components/header";

const Course = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [completedVideos, setCompletedVideos] = useState([]);
  const [selectedQuizIndex, setSelectedQuizIndex] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [nextButton, setNextButton] = useState(false);

  const [courseTitle, setCourseTitle] = useState('');

  const [content, setContent] = useState([]);

  const videoRef = useRef(null);

  const handleMenuItemSelect = () => {
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleVideoEnd = () => {
    if (!completedVideos.includes(currentVideoIndex)) {
      setCompletedVideos(prevCompletedVideos => [...prevCompletedVideos, currentVideoIndex])
      submitResponse(currentVideoIndex, null)
    }
  };

  const handleVideoSelect = (index) => {
    setCurrentVideoIndex(index);
    setSelectedQuizIndex(null);
  };

  const handleQuizSelect = (index) => {
    setSelectedQuizIndex(index);
  };

  const handleQuizSubmit = (index, optionIndex) => {
    const isCorrect = content[index].answer === optionIndex;

    setContent(prevContent =>
      prevContent.map((item, i) =>
        i === index ? { ...item, completed: true, isCorrect } : item
      )
    );

    const selectedButton = document.getElementById(`option-${index}-${optionIndex}`);

    if (isCorrect) {
      selectedButton.classList.add('correct-answer');

      submitResponse(index, content[index].options[optionIndex].id)

      setTimeout(() => {
        // Find the next index that is either a video or an unanswered question
        const nextIndex = content.findIndex(
          (item, i) => i > index && (item.type === 'video' || (item.type === 'question' && !item.completed))
        );

        if (nextIndex !== -1) {
          if (content[nextIndex].type === 'video') {
            setCurrentVideoIndex(nextIndex);
            setSelectedQuizIndex(null);
          } else {
            setSelectedQuizIndex(nextIndex);
          }
        } else {
          // If there are no more videos or quizzes, end the course
          // setCurrentVideoIndex(null);
          // setSelectedQuizIndex(null);

          navigate('/');
        }
      }, 300); // 5 seconds delay
    } else {
      selectedButton.classList.add('wrong-answer');
    }
  };

  useEffect(() => {
    try {
      if (currentVideoIndex !== null) {
        // videoRef.current.play();
      }
    } catch (error) { }
  }, [currentVideoIndex]);

  let [courseID, setCourseID] = useState(null);

  useEffect(() => {
    if (location.state && location.state.course_id) {
      setCourseID(location.state.course_id)
    } else {
      navigate('/')
      return
    }

    const getCourses = async () => {
      setLoading(true);

      const response = await fetch(process.env.REACT_APP_API + 'getCourse/' + location.state.course_id, { method: 'GET' });

      if (response.ok) {
        const responseData = await response.json();

        if (responseData['course']) {
          handleContent(responseData['course']['contents'], responseData['start_from_content'])
          setCourseTitle(responseData['course']['title'])
        } else {
          toast.error("لقد حدث خطأ ما!")
        }
      }

      setLoading(false);
    };

    getCourses()
  }, []);

  const handleContent = (responseContent, startFromContent) => {
    responseContent.forEach((item, index) => {
      // if content is question, compute answer

      if (item.type === 'question') {
        item.options.forEach((option, i) => {
          if (option['correct'] === "1") {
            item['answer'] = i
            return;
          }
        })
      }

      // if content is question, compute completed

      if (item.type === 'question') {
        if (startFromContent.id > item.id) {
          item['completed'] = true
        } else if (startFromContent.id === item.id) {
          setCurrentVideoIndex(null);
          setSelectedQuizIndex(index);
        }
      }

      // if content is video, check to add in videosCompleted

      if (item.type === 'video') {
        if (startFromContent.id > item.id && !completedVideos.includes(index)) {
          setCompletedVideos(prevCompletedVideos => [...prevCompletedVideos, index])
        }
        else if (startFromContent.id === item.id) {
          setCurrentVideoIndex(index);
          setSelectedQuizIndex(null);
        }
      }

    });

    setContent(responseContent)
  };

  const submitResponse = async (index, optionID) => {
    let data = {
      responses: [
        {
          course_id: courseID,
          content_id: content[index].id,
          optionID: optionID ?? undefined
        }
      ]
    }

    const response = await fetch(process.env.REACT_APP_API + 'submitResponse', {
      method: 'POST',
      body: JSON.stringify(data)
    });

    if (response.ok) {
      const responseData = await response.json();

      if (responseData['message']) {
        if (!optionID) {
          setNextButton(true)
        }
      } else {
        toast.error("لقد حدث خطأ ما!")
      }

    }
  };

  const onClickNext = () => {
    setNextButton(false)

    const nextIndex = content.findIndex(
      (item, i) => i > currentVideoIndex && (item.type === 'video' || (item.type === 'question' && !item.completed))
    );

    if (nextIndex !== -1) {
      if (content[nextIndex].type === 'video') {
        setCurrentVideoIndex(nextIndex);
        setSelectedQuizIndex(null);
      } else {
        setSelectedQuizIndex(nextIndex);
      }
    } else {
      navigate('/');
    }
  };

  const onClickHome = () => {
    navigate('/');
  };

  const findQuestionIndex = (questionIndex) => {
    const questions = content.filter(item => item.type === "question");
    return questions.findIndex(item => item.id === content[questionIndex].id) + 1
  };

  const courseList = (screen) => {
    return <div className={`menu-container ${screen}`}>
      <h5 style={{ fontWeight: '700', marginBottom: "30px" }}>{courseTitle}</h5>

      {content.map((item, index) => (
        <div className='menu-open' key={index}>
          {item.type === 'video' ? (
            <Link
              style={{ cursor: 'initial' }}
              className={`menu-item ${completedVideos.includes(index) ? 'completed' : ''}`}
            // onClick={() => {
            //   handleVideoSelect(index);
            //   handleMenuItemSelect();
            // }}
            >
              <FontAwesomeIcon color='#343434' icon={faVideo} className='menu-icon' />{' '}
              <span style={{ color: '#296260', marginRight: '10px' }}>{item.video_title}</span>{' '}
              {completedVideos.includes(index) && <span>&#10003;</span>}
            </Link>
          ) : (
            <Link
              style={{ cursor: 'initial' }}
              className={`menu-item ${selectedQuizIndex === index ? 'selected' : ''}`}
            // onClick={() => {
            //   handleQuizSelect(index);
            //   handleMenuItemSelect();
            // }}
            >
              <FontAwesomeIcon color='#343434' icon={faQuestion} className='menu-icon' />{' '}
              <span style={{ color: '#296260' }}>سؤال {findQuestionIndex(index)}</span>{' '}
              {item.completed && <span>&#10003;</span>}
            </Link>
          )}
        </div>
      ))}
    </div>
  };

  return (
    <main>
      <Toaster toastOptions={{ duration: 5000 }} position='bottom-center' />

      <Loading isLoading={loading} />

      <div className='course'>
        <Header toggleMenu={toggleMenu} menuOpen={menuOpen} />

        {content.length === 0
          ?
          null
          :
          (<div className='course-container'>

            {courseList('laptop')}

            <div className='course-separator'> </div>

            <div className='video-container grow size-full'>
              <h5 style={{ fontWeight: "700", fontFamily: 'Cairo', margin: "30px 0px 0px 0px" }}>{courseTitle}</h5>

              {
                selectedQuizIndex === null && currentVideoIndex === null
                  ?
                  null
                  :
                  (selectedQuizIndex === null
                    ?
                    (
                      <>
                        <div className='video-title'>{content[currentVideoIndex].video_title}</div>
                        <video
                          ref={videoRef}
                          // autoPlay
                          // muted
                          playsInline
                          controls
                          onEnded={handleVideoEnd}
                          src={content[currentVideoIndex].url}
                          poster={courseImage}
                          className='video-player'
                        />

                        <div className='buttons'>
                          {/* <button onClick={onClickHome} className="button submit btn-primary"> العودة إلى الرئيسية </button> */}
                          <button onClick={onClickNext} className="button submit button-green" id="btn_proceed" tabIndex="11" type="button" disabled={!nextButton}> استكمال </button>
                        </div>
                      </>
                    )
                    :
                    (
                      <div className='quiz-container'>
                        <h3>سؤال {findQuestionIndex(selectedQuizIndex)}</h3>
                        <p>{content[selectedQuizIndex].question_text}</p>
                        <ul style={{ width: '100%' }}>
                          {content[selectedQuizIndex].options.map((option, optionIndex) => (
                            <button
                              key={optionIndex}
                              id={`option-${selectedQuizIndex}-${optionIndex}`}
                              onClick={() => handleQuizSubmit(selectedQuizIndex, optionIndex)}
                            >
                              {option['option']}
                            </button>
                          ))}
                        </ul>
                      </div>
                    )
                  )
              }

              <div style={{ height: '50px' }}></div>
            </div>

            {menuOpen && (
              courseList('mobile')
            )}
          </div>)
        }

      </div>
    </main>
  );
};

export default Course;
