import React, { useState, useEffect, useRef } from 'react';
import "./payment-verification.css";

import { useNavigate } from "react-router-dom";

import { Toaster } from "react-hot-toast"

import Loading from '../../components/loading';
import ProgressLoading from '../../components/progress-loading';

import PaymentVerificationStepper4 from '../payment-verification-stepper/payment-verification-stepper-4';
import PaymentVerificationStepper5 from '../payment-verification-stepper/payment-verification-stepper-5';
import PaymentVerificationStepper6 from '../payment-verification-stepper/payment-verification-stepper-6';
import Header from '../../components/header';

const PaymentVerification = () => {
    const navigate = useNavigate();

    let [loading, setLoading] = useState(false);
    let [loadingProgress, setLoadingProgress] = useState(false);

    const paymentVerificationStepper4Ref = useRef(null);
    const paymentVerificationStepper5Ref = useRef(null);
    const paymentVerificationStepper6Ref = useRef(null);


    let [stepper, setStepper] = useState(4);
    let [stepperValidation, setStepperValidation] = useState({
        4: false,
        5: false,
        6: false
    });

    useEffect(() => {
        const userStatus = Number(localStorage.getItem('status'));

        if (userStatus && userStatus >= 4 && userStatus < 7) {
            setStepper(userStatus)
        } else {
            navigate('/');
        }

    }, []);


    const onClickNext = () => {
        if (stepper === 6) {

            if (paymentVerificationStepper6Ref.current) {
                paymentVerificationStepper6Ref.current.surveyInfo();
            }
        } else if (stepper === 5) {
            if (paymentVerificationStepper5Ref.current) {
                paymentVerificationStepper5Ref.current.updatePassword();
            }
        } else {
            if (paymentVerificationStepper4Ref.current) {
                paymentVerificationStepper4Ref.current.uploadReceipt();
            }
        }
    };

    return (
        <main>
            <ProgressLoading isLoading={loadingProgress} />

            <Toaster toastOptions={{ duration: 5000 }} position='bottom-center' />

            <Loading isLoading={loading} />

            <div className='payment-verification'>
                <Header />

                <div className="registration-flow">
                    <div className="heading">
                        <div className="icon-holder">
                            <i className="icomoon-register"></i>
                        </div>

                        <div className="info">
                            <div className="main-title">تسجيل حساب في ecat</div>
                            <div className="sec-title">أدخل جميع البيانات المطلوبة في الحقول المميزة بالعلامة (*).</div>
                        </div>
                    </div>
                    <ul className="registration-step ng-star-inserted">
                        <li style={{ margin: '0 2%' }} className='done'>1</li>
                        <li style={{ margin: '0 2%' }} className='done'>2</li>
                        <li style={{ margin: '0 2%' }} className='done'>3</li>
                        <li style={{ margin: '0 2%' }} className={stepper > 4 ? 'done' : stepper === 4 ? 'active' : ''}>4</li>
                        <li style={{ margin: '0 2%' }} className={stepper > 5 ? 'done' : stepper === 5 ? 'active' : ''}>5</li>
                        <li style={{ margin: '0 2%' }} className={stepper > 6 ? 'done' : stepper === 6 ? 'active' : ''}>6</li>
                    </ul>
                </div>

                <div className="content">

                    {
                        stepper === 4 ?
                            <PaymentVerificationStepper4
                                ref={paymentVerificationStepper4Ref}
                                setLoading={setLoading}
                                setLoadingProgress={setLoadingProgress}
                                stepper={stepper}
                                setStepper={setStepper}
                                setStepperValidation={setStepperValidation}
                            />
                            :
                            stepper === 5 ?
                                <PaymentVerificationStepper5
                                    ref={paymentVerificationStepper5Ref}
                                    setLoading={setLoading}
                                    stepper={stepper}
                                    setStepper={setStepper}
                                    setStepperValidation={setStepperValidation}

                                /> :
                                stepper === 6 ?
                                    <PaymentVerificationStepper6
                                        ref={paymentVerificationStepper6Ref}
                                        navigate={navigate}
                                        setLoading={setLoading}
                                        setStepperValidation={setStepperValidation}
                                    /> :
                                    null
                    }



                    <div className="buttons">
                        <button onClick={onClickNext} className="button submit button-green" id="btn_proceed" tabIndex="11" type="button" disabled={!stepperValidation[stepper]}> استكمال </button>
                    </div>


                </div>

            </div>
        </main>
    );
};

export default PaymentVerification;
