import React, { useState, useEffect } from 'react';
import "../registration/registration.css";


import { FaCheckCircle } from "react-icons/fa";

import toast, { Toaster } from "react-hot-toast"
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'

const RegistrationStepper1 = ({ setLoading, setStepper, setStepperValidation, setFormData }) => {
    //  --------------- STEP 1 ----------------------

    const [phone, setPhone] = useState('')
    const [OTP, setOTP] = useState('')
    const [sendOTP, setSendOTP] = useState(true)
    const [resendOTP, setReSendOTP] = useState(null)
    const [verifyOTP, setVerifyOTP] = useState(true)

    const [email, setEmail] = useState('')
    const [OTPEmail, setOTPEmail] = useState('')
    const [sendOTPEmail, setSendOTPEmail] = useState(true)
    const [resendOTPEmail, setReSendOTPEmail] = useState(null)
    const [verifyOTPEmail, setverifyOTPEmail] = useState(true)

    const [isPhoneVerified, setIsPhoneVerified] = useState(false)
    const [isEmailVerified, setIsEmailVerified] = useState(false)

    const [isPhoneSendOTPClicked, setIsPhoneSendOTPClicked] = useState(false)
    const [isEmailSendOTPClicked, setIsEmailSendOTPClicked] = useState(false)

    const handlePhoneNumberChange = (phone) => {
        setPhone(phone);
        setSendOTP(false)
    };

    const handleOTPChange = (event) => {
        setOTP(event.target.value);

        const regex = /^[0-9]{6}$/;

        if (regex.test(event.target.value)) {
            setVerifyOTP(false)
        } else {
            setVerifyOTP(true)
        }

    };

    const handleSendOTPClick = async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_API + 'createUser', {
            method: 'POST',
            body: JSON.stringify({
                phone: phone,
            }),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message']['otp'] && responseData['message']['otp'] === "OTP sent") {
                setIsPhoneSendOTPClicked(true)

                setReSendOTP(59)

            }

        }

        setLoading(false);
    };

    const handleVerifyOTPClick = async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_API + 'verifyOtp', {
            method: 'POST',
            body: JSON.stringify({
                phone: phone,
                otp: OTP
            }),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message']['otp'] === "valid otp") {
                localStorage.setItem('user', JSON.stringify(responseData['message']['user']));
                localStorage.setItem('status', responseData['message']['user']['status']);

                fetchUserLocation();

                setIsPhoneVerified(true)

                localStorage.setItem('status', 1);
            } else if (responseData['message'] && responseData['message']['otp'] === "invalid otp") {
                toast.error("كلمة المرور لمرة واحدة غير صحيحة!")
            } else if (responseData['error'] && responseData['error'] === "Continue registration") {
                localStorage.setItem('user', JSON.stringify(responseData['user']));
                localStorage.setItem('status', responseData['user']['status']);

                fetchUserLocation();

                // Verify phone with OTP
                if (responseData['user']['status'] < 1) {
                    setIsPhoneSendOTPClicked(true)

                }
                // Continue registration
                else if (responseData['user']['status'] >= 1 && responseData['user']['status'] <= 3) {
                    setIsPhoneVerified(true)
                    setStepper(responseData['user']['status'])

                    if (responseData['user']['email']) {
                        setEmail(responseData['user']['email'])

                        if (isValidEmail(responseData['user']['email'])) {
                            setSendOTPEmail(false)
                        } else {
                            setSendOTPEmail(true)
                        }
                    }

                    if (responseData['user']['status'] === 3) {
                        setFormData(prevFormData => {
                            const updatedFormData = {
                                ...prevFormData,
                                ['userName']: responseData['user']['full_name'],
                                ['nationality']: responseData['user']['nationality'],
                                ['age']: responseData['user']['age'],
                                ['gender']: responseData['user']['Gender'],
                                ['passportNumber']: responseData['user']['passport_number'],
                                ['expirationDate']: responseData['user']['expiration_date'],
                            };

                            return updatedFormData;
                        });
                    }

                }
            } else {
                toast.error("لقد حدث خطأ ما!")
            }
        }

        setLoading(false);
    };

    const isValidEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);

        setIsEmailSendOTPClicked(false)

        if (isValidEmail(event.target.value)) {
            setSendOTPEmail(false)
        } else {
            setSendOTPEmail(true)
        }

    };

    const handleOTPEmailChange = (event) => {
        setOTPEmail(event.target.value);

        const regex = /^[0-9]{6}$/;

        if (regex.test(event.target.value)) {
            setverifyOTPEmail(false)
        } else {
            setverifyOTPEmail(true)
        }

    };

    const handleSendOTPEmailClick = async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_API + 'sendEmailOTP', {
            method: 'POST',
            body: JSON.stringify({
                email: email,
            }),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message'] === "Email sent successfully!") {
                setIsEmailSendOTPClicked(true)

                setReSendOTPEmail(59)
            } else {
                toast.error("لقد حدث خطأ ما!")
            }
        }

        setLoading(false);
    };

    const handleVerifyOTPEmailClick = async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_API + 'verifyMailOtp', {
            method: 'POST',
            body: JSON.stringify({
                mail_otp: OTPEmail,
            }),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message']['otp'] && responseData['message']['otp'] == "valid otp") {
                setIsEmailVerified(true)

                setStepperValidation(prevState => ({
                    ...prevState,
                    1: true
                }));

                localStorage.setItem('status', 2);

            } else if (responseData['message'] && responseData['message']['otp'] === "invalid otp") {
                toast.error("كلمة المرور لمرة واحدة غير صحيحة!")
            } else {
                toast.error("لقد حدث خطأ ما!")
            }
        }

        setLoading(false);
    };

    const fetchUserLocation = () => {
        const userJSON = localStorage.getItem('user');
        const user = JSON.parse(userJSON);
        let apiToken = user['api_token'];

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const locationData = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                };
                sendLocationToServer(locationData, apiToken);
            }, error => {
                toast.error("Error obtaining location: " + error.message);
            });
        } else {
            toast.error("Geolocation is not supported by this browser.");
        }
    };

    const sendLocationToServer = async (locationData, apiToken) => {
        try {
            const response = await fetch(process.env.REACT_APP_API + "userLocation", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${apiToken}`
                },
                body: JSON.stringify(locationData),
            });

            const responseData = await response.json();
            if (!response.ok)
                throw new Error(responseData.message || "Failed to send location");
            console.log("Location sent successfully:", responseData);
        } catch (error) {
            toast.error("Error sending location: " + error.message);
        }
    };

    useEffect(() => {
        const userStatus = Number(localStorage.getItem('status'));

        if (userStatus) {
            if (userStatus >= 1 && userStatus <= 3) {
                setIsPhoneVerified(true)
            }
        }

    }, []);

    useEffect(() => {
        if (resendOTP) {
            setTimeout(() => {
                setReSendOTP(resendOTP == 0 ? resendOTP : resendOTP - 1)
            }, 1000);
        }

    }, [resendOTP]);

    useEffect(() => {
        if (resendOTPEmail) {
            setTimeout(() => {
                setReSendOTPEmail(resendOTPEmail == 0 ? resendOTPEmail : resendOTPEmail - 1)
            }, 1000);
        }

    }, [resendOTPEmail]);

    return (
        <main>
            <div className="inner-content">
                <div className="main-title form-title">بيانات الاتصال</div>
                <div className="sec-title">تأكد من إدخال رقم هاتفك الشخصي وبريدك الإلكتروني.</div>

                <form autoComplete="off" className="mb-4" noValidate>
                    {
                        isPhoneVerified
                            ?
                            (<div className='verify-success'>
                                <FaCheckCircle style={{ color: 'green', fontSize: '100px' }} />
                                <label className="main-label" > تم التحقق من هاتفك بنجاح </label>
                            </div>)
                            :
                            (<div>
                                <label className="main-label" style={{ marginTop: '50px' }}> التحقق من رقم هاتفك المحمول </label>
                                <div className="sec-title text-left" style={{ marginBottom: '30px', textAlign: 'start !important' }}> أدخل رقم هاتفك المحمول، ثم استخدم زر ’إرسال‘ لتستقبل رمز التحقق. </div>
                                <label className="main-label">رقم المحمول*</label>

                                <div className="more-inputs">
                                    <PhoneInput
                                        value={phone}
                                        preferredCountries={['eg']}
                                        placeholder='+20 1XX XXXX XXX'
                                        country={"eg"}
                                        // disableInitialCountryGuess={true}
                                        onChange={phone => handlePhoneNumberChange(phone)}
                                        disabled={isPhoneSendOTPClicked}
                                    />

                                    <button onClick={handleSendOTPClick} className="button button-blue ng-star-inserted" id="btn_verifyOtp" tabIndex="10" type="button" disabled={sendOTP || (isPhoneSendOTPClicked && resendOTP != 0)}>
                                        <i className="ng-star-inserted"></i> {(isPhoneSendOTPClicked && resendOTP != 0) ? ('إعادة الإرسال: ' + resendOTP) : ('إرسال')}</button>
                                </div>

                                {isPhoneSendOTPClicked
                                    ?
                                    (<div>
                                        <label className="main-label">رمز التحقق*</label>

                                        <div className="more-inputs">
                                            <input onChange={handleOTPChange} className="main-input" id="otp_input" maxLength="6" name="oTPtxt" placeholder="124356" tabIndex="9" type="text" fdprocessedid="sp2zi2" />
                                            <button onClick={handleVerifyOTPClick} className="button button-blue ng-star-inserted" id="btn_verifyOtp" tabIndex="10" type="button" disabled={verifyOTP}>
                                                <i className="ng-star-inserted"></i>تحقق </button>
                                        </div>
                                    </div>)
                                    :
                                    (null)}

                            </div>)
                    }

                    {
                        isEmailVerified
                            ?
                            (<div className='verify-success'>
                                <FaCheckCircle style={{ color: 'green', fontSize: '100px' }} />
                                <label className="main-label" > تم التحقق من بريدك الإلكتروني بنجاح </label>
                            </div>)
                            :
                            (<div className='verify-email'>
                                <label className="main-label" style={{ marginTop: '50px' }}> التحقق من بريدك الإلكتروني </label>
                                <div className="sec-title text-left" style={{ marginBottom: '30px', textAlign: 'start !important' }}> أدخل بريدك الإلكتروني، ثم استخدم زر ’إرسال‘ لتستقبل رمز التحقق. </div>
                                <label className="main-label">البريد الإلكتروني*</label>

                                <div className="more-inputs">
                                    <input value={email} onChange={handleEmailChange} style={{ direction: 'ltr' }} className="main-input" id="email_input" name="email_input" placeholder="abc@xyz.com" tabIndex="9" type="text" fdprocessedid="sp2zi2" />
                                    <button onClick={handleSendOTPEmailClick} className="button button-blue ng-star-inserted" id="btn_verifyOtp" tabIndex="10" type="button" disabled={sendOTPEmail || (isEmailSendOTPClicked && resendOTPEmail != 0)}>
                                        <i className="ng-star-inserted"></i> {(isEmailSendOTPClicked && resendOTPEmail != 0) ? ('إعادة الإرسال: ' + resendOTPEmail) : ('إرسال')}</button>
                                </div>

                                {isEmailSendOTPClicked
                                    ?
                                    (<div>
                                        <label className="main-label">رمز التحقق*</label>

                                        <div className="more-inputs">
                                            <input onChange={handleOTPEmailChange} className="main-input" id="otp_email_input" maxLength="6" name="oTPtxt" placeholder="124356" tabIndex="9" type="text" fdprocessedid="sp2zi2" />
                                            <button onClick={handleVerifyOTPEmailClick} className="button button-blue ng-star-inserted" id="btn_verifyOtp" tabIndex="10" type="button" disabled={verifyOTPEmail}>
                                                <i className="ng-star-inserted"></i>تحقق </button>
                                        </div>
                                    </div>)
                                    :
                                    (null)}


                            </div>)
                    }

                </form>
            </div>
        </main>
    );
};

export default RegistrationStepper1;
