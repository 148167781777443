import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import "./registration.css";

import { Toaster } from "react-hot-toast"

import Loading from '../../components/loading';
import ProgressLoading from '../../components/progress-loading';

import RegistrationStepper1 from '../registration-stepper/registration-stepper-1';
import RegistrationStepper2 from '../registration-stepper/registration-stepper-2';
import RegistrationStepper3 from '../registration-stepper/registration-stepper-3';
import Header from '../../components/header';

const Registration = () => {

  //  --------------- MAIN ----------------------
  let [loading, setLoading] = useState(false);
  let [loadingProgress, setLoadingProgress] = useState(false);

  let [stepper, setStepper] = useState(1);
  let [stepperValidation, setStepperValidation] = useState({
    1: false,
    2: false,
    3: false,
  });

  const navigate = useNavigate();

  const registrationStepper2Ref = useRef(null);
  const registrationStepper3Ref = useRef(null);

  const [formData, setFormData] = useState({
    userName: '',
    nationality: '',
    age: '',
    gender: '',
    passportNumber: '',
    expirationDate: '',
    mainService: '',
    selectedFamilyMembers: '',
  });

  const onClickNext = () => {
    if (stepper === 3) {

      if (registrationStepper3Ref.current) {
        registrationStepper3Ref.current.submitPassportInfo();
      }

    } else if (stepper === 2) {

      if (registrationStepper2Ref.current) {
        registrationStepper2Ref.current.uploadFacePassport();
      }

    } else {
      setStepper(stepper + 1)
      window.scrollTo(0, 0);
    }

  };

  const onClickBack = () => {
    setStepper(stepper - 1)
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const userStatus = Number(localStorage.getItem('status'));

    if (userStatus) {
      if (userStatus >= 1 && userStatus <= 3) {
        setStepper(userStatus)
      } else {
        navigate('/');
      }
    }

  }, []);

  return (
    <main>
      <ProgressLoading isLoading={loadingProgress} />

      <Toaster toastOptions={{ duration: 5000 }} position='bottom-center' />

      <Loading isLoading={loading} />

      <div className='registration'>
        <Header />

        <div className="registration-flow">
          <div className="heading">
            <div className="icon-holder">
              <i className="icomoon-register"></i>
            </div>

            <div className="info">
              <div className="main-title">تسجيل حساب في ecat</div>
              <div className="sec-title">أدخل جميع البيانات المطلوبة في الحقول المميزة بالعلامة (*).</div>
            </div>
          </div>
          <ul className="registration-step ng-star-inserted">
            <li className={stepper > 1 ? 'done' : stepper === 1 ? 'active' : ''}>1</li>
            <li className={stepper > 2 ? 'done' : stepper === 2 ? 'active' : ''}>2</li>
            <li className={stepper > 3 ? 'done' : stepper === 3 ? 'active' : ''}>3</li>
          </ul>
        </div>

        <div className="content">

          {stepper === 1 ?
            <RegistrationStepper1
              setLoading={setLoading}
              setStepper={setStepper}
              setStepperValidation={setStepperValidation}
              setFormData={setFormData} />
            :
            stepper === 2 ?
              <RegistrationStepper2
                ref={registrationStepper2Ref}
                setLoading={setLoading}
                setLoadingProgress={setLoadingProgress}
                stepper={stepper}
                setStepper={setStepper}
                setStepperValidation={setStepperValidation}
                setFormData={setFormData}
                isStepperValidated={stepperValidation[2]} />
              :
              stepper === 3 ?
                <RegistrationStepper3
                  ref={registrationStepper3Ref}
                  setLoading={setLoading}
                  setLoadingProgress={setLoadingProgress}
                  setStepperValidation={setStepperValidation}
                  formData={formData}
                  setFormData={setFormData}
                  navigate={navigate}
                />
                : null}

          <div className="buttons">
            <button onClick={onClickNext} className="button submit button-green" id="btn_proceed" tabIndex="11" type="button" disabled={!stepperValidation[stepper]}> استكمال </button>

            {stepper > 1 && (<button onClick={onClickBack} className="button button-grey" tabIndex="12" type="button" fdprocessedid="1mkbvs"> السابق </button>)}

          </div>


        </div>

      </div>
    </main>
  );
};

export default Registration;
