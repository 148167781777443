import React, { useState, useEffect, useContext } from 'react';
import "./login.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'

import toast, { Toaster } from "react-hot-toast"

import Loading from '../../components/loading';

import { GoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import Header from '../../components/header';

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let [loading, setLoading] = useState(false);

  const [canLogin, setCanLogin] = useState(false);

  const [formData, setFormData] = useState({
    user_reference_number: '',
    password: '',
  });

  const handleInputChange = (fieldName, value) => {
    setFormData(prevFormData => {
      const updatedFormData = {
        ...prevFormData,
        [fieldName]: value
      };

      const regex = /^[\w\d]{10}$/;

      if (regex.test(updatedFormData['user_reference_number']) && updatedFormData['password'] != '') {
        setCanLogin(true)
      } else {
        setCanLogin(false)
      }


      return updatedFormData;
    });
  };

  useEffect(() => {
    const userStatus = Number(localStorage.getItem('status'));

    if (userStatus) {
      navigate('/');
    }

    if (location.state) {
      if (location.state.message) {
        toast.success(location.state.message)
      } else if (location.state.error) {
        toast.error(location.state.error)
      }
    }
  }, []);

  const onClickLogin = async () => {
    setLoading(true);

    const response = await fetch(process.env.REACT_APP_API + 'login', {
      method: 'POST',
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      const responseData = await response.json();

      if (responseData['message'] && responseData['message'] === "Login successfully") {
        localStorage.setItem('user', JSON.stringify(responseData['user']));
        localStorage.setItem('status', responseData['user']['status']);

        fetchUserLocation(responseData['user']['api_token']);

        if (responseData['user']['status'] >= 4 && responseData['user']['status'] < 7) {
          navigate('/payment-verification');
        } else if (responseData['user']['status'] >= 7) {
          navigate('/');
        } else {
          navigate('/registration');
        }


      } else {
        toast.error("لقد حدث خطأ ما!")
      }
    }

    setLoading(false);
  };

  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      var decoded = jwtDecode(credentialResponse.credential);
      onClickLoginGoogle(decoded.email);
    },
    onError: () => {
      toast.error("لقد حدث خطأ ما!");
    },
  });

  const onClickLoginGoogle = async (email) => {
    setLoading(true);

    const response = await fetch(process.env.REACT_APP_API + 'loginWithGoogle', {
      method: 'POST',
      body: JSON.stringify({
        email: email,
      }),
    });

    if (response.ok) {
      const responseData = await response.json();

      if (responseData['message'] && responseData['message'] === "Login successfully") {
        localStorage.setItem('user', JSON.stringify(responseData['user']));
        localStorage.setItem('status', responseData['user']['status']);

        fetchUserLocation(responseData['user']['api_token']);

        if (responseData['user']['status'] >= 4 && responseData['user']['status'] < 7) {
          navigate('/payment-verification');
        } else if (responseData['user']['status'] >= 7) {
          navigate('/');
        } else {
          navigate('/registration');
        }


      } else {
        toast.error("لقد حدث خطأ ما!")
      }
    }

    setLoading(false);
  };

  const fetchUserLocation = (apiToken) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const locationData = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        sendLocationToServer(locationData, apiToken);
      }, error => {
        toast.error("Error obtaining location: " + error.message);
      });
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };

  const sendLocationToServer = async (locationData, apiToken) => {
    try {
      const response = await fetch(process.env.REACT_APP_API + "userLocation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${apiToken}`
        },
        body: JSON.stringify(locationData),
      });

      const responseData = await response.json();
      if (!response.ok)
        throw new Error(responseData.message || "Failed to send location");
      console.log("Location sent successfully:", responseData);
    } catch (error) {
      toast.error("Error sending location: " + error.message);
    }
  };

  return (
    <main>
      <Toaster toastOptions={{ duration: 5000 }} position='bottom-center' />

      <Loading isLoading={loading} />

      <div className='login'>
        <Header />

        <div className='login-flow'>
          <div className="heading">
            <div className="info">
              <div className="main-title">سجل الدخول إلى حسابك</div>
              <div className="sec-title">أدخل جميع البيانات المطلوبة في الحقول المميزة بالعلامة (*).
              </div>
            </div>
          </div>
        </div>

        <div className="content login">
          <div className="inner-content">
            <form noValidate="" className="ng-untouched ng-pristine ng-invalid">
              <label className="main-label">رقم الحساب *</label>
              <div className="input-holder">
                <input className="main-input icon-input ng-untouched ng-pristine ng-invalid" maxLength="10" name="txtFanId" placeholder="رقم الحساب" required="" type="text"
                  onChange={(e) => handleInputChange('user_reference_number', e.target.value)}
                />
                <i className="icomoon-user-info"></i>
              </div>
              <label className="main-label">كلمة المرور *</label>

              <div className="input-holder">
                <Link className="input-comment forgot" style={{ left: '125px' }} to={'/forget-id'}> نسيت رقم الحساب؟ </Link>
                <Link className="input-comment forgot" to={'/forget-password'}> نسيت كلمة المرور؟ </Link>
                <input className="main-input icon-input ng-untouched ng-pristine ng-invalid" maxLength="20" name="txtPassword" required="" type="password" placeholder="كلمة المرور"
                  onChange={(e) => handleInputChange('password', e.target.value)}
                />
                <i className="icomoon-lock"></i>
              </div>

              <div className="text-center sec-title need-an-account">
                <span>لا تمتلك حساب؟</span>
                <Link className="text-green" to={'/registration'}>

                  <FontAwesomeIcon color='#219f45' style={{ fontSize: '18px', margin: '0px 5px' }} icon={faUserPlus} />
                  <span>اشترك الآن!</span>

                </Link>
              </div>

              <div className='login-buttons'>
                <button className="button button-green" type="button" onClick={onClickLogin} disabled={!canLogin}> دخول </button>

                <GoogleLogin
                  onSuccess={credentialResponse => {
                    var decoded = jwtDecode(credentialResponse.credential);
                    onClickLoginGoogle(decoded.email);
                  }}
                  onError={() => {
                    toast.error("لقد حدث خطأ ما!");
                  }}
                />;
              </div>

            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
