import React, { useState, forwardRef, useImperativeHandle } from 'react';
import "../payment-verification/payment-verification.css";

const PaymentVerificationStepper6 = forwardRef((props, ref) => {
    const {
        navigate,
        setLoading,
        setStepperValidation
    } = props;

    const motherLanguages = [
        'العربية',
        'الإنجليزية',
        'الصينية',
        'الهندية',
        'الإسبانية',
        'الروسية',
        'البرتغالية',
        'البنغالية',
        'الفرنسية',
        'الألمانية',
        'أخرى'
    ]

    const governorate = [
        "القاهرة",
        "الجيزة",
        "الإسكندرية",
        "الدقهلية",
        "البحر الأحمر",
        "البحيرة",
        "الفيوم",
        "الغربية",
        "الإسماعيلية",
        "المنوفية",
        "المنيا",
        "القليوبية",
        "الوادي الجديد",
        "السويس",
        "أسوان",
        "أسيوط",
        "بني سويف",
        "بورسعيد",
        "دمياط",
        "الشرقية",
        "جنوب سيناء",
        "كفر الشيخ",
        "مطروح",
        "الأقصر",
        "قنا",
        "شمال سيناء",
        "سوهاج"
    ];

    const educationalDegree = [
        'شهادة التعليم الابتدائي',
        'شهادة التعليم الإعدادي',
        'الثانوية العامة',
        'دبلوم',
        'شهادة تقنية',
        'البكالوريوس',
        'الماجستير',
        'الدكتوراه'
    ]

    const socialMediaLinks = [
        'https://www.facebook.com/',
        'https://www.instagram.com/',
        'https://www.tiktok.com/',
    ]

    const [socialMediaLinkValue, setSocialMediaLinkValue] = useState('https://www.facebook.com/');


    const [formData, setFormData] = useState({
        motherLanguage: '',
        governorate: '',
        socialMediaLink: '',
        educationalDegree: '',
        occupation: ''
    });

    const handleInputChange = (fieldName, value) => {
        setFormData(prevFormData => {
            if (fieldName === 'socialMediaLink') {
                value = socialMediaLinkValue + value
            }

            const updatedFormData = {
                ...prevFormData,
                [fieldName]: value
            };

            const flag = Object.values(updatedFormData).every(fieldValue => fieldValue !== '');

            setStepperValidation(prevState => ({
                ...prevState,
                6: flag
            }));

            return updatedFormData;
        });
    };

    useImperativeHandle(ref, () => ({
        surveyInfo
    }));

    const surveyInfo = async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_API + 'surveyInfo', {
            method: 'POST',
            body: JSON.stringify(formData),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message'] === "Survey created successfully!") {
                localStorage.setItem('status', 7);
                navigate('/');
            }
        }

        setLoading(false);
    };

    return (
        <div className="inner-content">
            <div className="main-title form-title">بيانات شخصية اضافية</div>

            <form autoComplete="off" className="mb-4" noValidate>
                <div className='verify-phone'>

                    <label className="main-label">اللغة الأم</label>

                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {formData['motherLanguage'] === '' ? 'إختر اللغة الأم' : formData['motherLanguage']}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {motherLanguages.map((item, index) => (
                                <a key={index} className="dropdown-item" onClick={() => handleInputChange('motherLanguage', item)}>{item}</a>
                            ))}

                        </div>
                    </div>

                    <label className="main-label">محل الإقامة</label>

                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {formData['governorate'] === '' ? 'إختر محل الإقامة' : formData['governorate']}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {governorate.map((item, index) => (
                                <a key={index} className="dropdown-item" onClick={() => handleInputChange('governorate', item)}>{item}</a>
                            ))}

                        </div>
                    </div>

                    <label className="main-label">روابط وسائل التواصل الاجتماعي</label>

                    <div className="socail-media-link">
                        <div className="dropdown" style={{ flex: 1 }}>
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {socialMediaLinkValue}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {socialMediaLinks.map((item, index) => (
                                    <a key={index} className="dropdown-item" onClick={() => setSocialMediaLinkValue(item)}>{item}</a>
                                ))}

                            </div>
                        </div>

                        <input className="main-input" style={{ flex: 1 }} tabIndex="1" type="text" placeholder="إسم المستخدم" onChange={(e) => handleInputChange('socialMediaLink', e.target.value)} />

                    </div>

                    <label className="main-label">أعلى مستوى تعليمي</label>

                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {formData['educationalDegree'] === '' ? 'إختر أعلى مستوى تعليمي' : formData['educationalDegree']}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {educationalDegree.map((item, index) => (
                                <a key={index} className="dropdown-item" onClick={() => handleInputChange('educationalDegree', item)}>{item}</a>
                            ))}

                        </div>
                    </div>

                    <label className="main-label">الوظيفة</label>
                    <input className="main-input" tabIndex="1" type="text" placeholder="(مثال: مهندس مدني)" onChange={(e) => handleInputChange('occupation', e.target.value)} />

                </div>

            </form>
        </div>
    );
});

export default PaymentVerificationStepper6;
