import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./forget-password.css";

import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FaCheckCircle } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import toast, { Toaster } from "react-hot-toast"
import PhoneInput from "react-phone-input-2";

import Loading from '../../components/loading';
import Header from "../../components/header";

const ForgetPassword = () => {
    const navigate = useNavigate();

    let [loading, setLoading] = useState(false);

    const [phone, setPhone] = useState('')
    const [OTP, setOTP] = useState('')
    const [verifyOTP, setVerifyOTP] = useState(true)
    const [sendButton, setSendButton] = useState(true)
    const [isPhoneSendOTPClicked, setIsPhoneSendOTPClicked] = useState(false)
    const [isPhoneVerified, setIsPhoneVerified] = useState(false)

    let [password, setPassword] = useState({
        password: '',
        retryPassword: ''
    });

    let [passwordValidation, setPasswordValidation] = useState({
        length: false,
        special: false,
        match: false
    });

    useEffect(() => {
        const userStatus = Number(localStorage.getItem('status'));

        if (userStatus) {
            navigate('/');
        }

    }, []);

    const handlePhoneNumberChange = (phone) => {
        setPhone(phone);
        setSendButton(false)
    };

    const resetPasswordSendOTP = async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_API + 'resetPasswordSendOTP', {
            method: 'POST',
            body: JSON.stringify({
                "phone": phone
            }),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message'] === "OTP sent") {
                setIsPhoneSendOTPClicked(true)
            } else {
                toast.error("لقد حدث خطأ ما!")
            }
        }

        setLoading(false);
    };

    const handleOTPChange = (event) => {
        setOTP(event.target.value);

        const regex = /^[0-9]{6}$/;

        if (regex.test(event.target.value)) {
            setVerifyOTP(false)
        } else {
            setVerifyOTP(true)
        }

    };

    const resetPasswordVerifyOTP = async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_API + 'resetPasswordVerifyOTP', {
            method: 'POST',
            body: JSON.stringify({
                phone: phone,
                otp: OTP
            }),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message']['otp'] === "valid otp") {
                localStorage.setItem('user', JSON.stringify(responseData['message']['user']));
                setIsPhoneVerified(true)
            } else if (responseData['message'] && responseData['message']['otp'] === "invalid otp") {
                toast.error("كلمة المرور لمرة واحدة غير صحيحة!")
            } else {
                toast.error("لقد حدث خطأ ما!")
            }
        }

        setLoading(false);
    };

    const handlePasswordChange = (fieldName, value) => {
        setPassword(prevPassword => {
            const updatedPassword = {
                ...prevPassword,
                [fieldName]: value
            };

            let length = false

            if (updatedPassword['password'].length >= 8) {
                length = true
            }

            const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

            let special = true

            if (!specialCharacters.test(updatedPassword['password'])) {
                special = false
            }

            let match = false

            if (updatedPassword['password'] === updatedPassword['retryPassword']) {
                match = true
            }

            setPasswordValidation(prevPWV => {
                const updatedPWV = {
                    ...prevPWV,
                    ['length']: length,
                    ['special']: special,
                    ['match']: match
                };

                return updatedPWV;
            });

            return updatedPassword;
        });
    };

    const resetPassword = async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_API + 'resetPassword', {
            method: 'POST',
            body: JSON.stringify({
                password: password['password']
            }),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message'] === "Password updated successfully") {
                localStorage.removeItem('user')
                localStorage.removeItem('status')
                navigate('/login', { state: { message: "لقد تم تغيير كلمة المرور بنجاح!" } });
            } else {
                toast.error("لقد حدث خطأ ما!")
            }
        }

        setLoading(false);
    };

    return (
        <main>
            <Toaster toastOptions={{ duration: 5000 }} position='bottom-center' />

            <Loading isLoading={loading} />

            <div className='forget-password'>
                <Header />

                <div className='forget-password-flow'>
                    <div className="heading">
                        <div className="info">
                            <div className="main-title">إعادة تعيين كلمة المرور لحساب ecat</div>
                            <div className="sec-title">أدخل جميع البيانات المطلوبة في الحقول المميزة بالعلامة (*).
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content login">
                    <div className="inner-content">
                        <form noValidate="" className="ng-untouched ng-pristine ng-invalid">
                            {
                                !isPhoneVerified ?
                                    <div>
                                        <label className="main-label">رقم الهاتف *</label>

                                        <div className="more-inputs">
                                            <PhoneInput
                                                value={phone}
                                                preferredCountries={['eg']}
                                                placeholder='+20 1XX XXXX XXX'
                                                country={"eg"}
                                                onChange={phone => handlePhoneNumberChange(phone)}
                                                disabled={isPhoneSendOTPClicked}
                                            />

                                            <button onClick={resetPasswordSendOTP} className="button button-blue ng-star-inserted" id="btn_verifyOtp" tabIndex="10" type="button" disabled={sendButton || isPhoneSendOTPClicked}>
                                                <i className="ng-star-inserted"></i>إرسال</button>
                                        </div>


                                        {isPhoneSendOTPClicked
                                            ?
                                            (<div>
                                                <label className="main-label">رمز التحقق*</label>

                                                <div className="more-inputs">
                                                    <input onChange={handleOTPChange} className="main-input" id="otp_input" maxLength="6" name="oTPtxt" placeholder="124356" tabIndex="9" type="text" fdprocessedid="sp2zi2" />
                                                    <button onClick={resetPasswordVerifyOTP} className="button button-blue ng-star-inserted" id="btn_verifyOtp" tabIndex="10" type="button" disabled={verifyOTP}>
                                                        <i className="ng-star-inserted"></i>تحقق </button>
                                                </div>
                                            </div>)
                                            :
                                            (null)}

                                    </div> : null
                            }

                            {isPhoneVerified
                                ?
                                (<div>
                                    <div className='verify-phone'>

                                        <label className="main-label">كلمة المرور</label>
                                        <input className="main-input" tabIndex="1" type="password" placeholder="كلمة المرور" onChange={(e) => handlePasswordChange('password', e.target.value)} />

                                        <label className="main-label">إعادة كلمة المرور</label>
                                        <input className="main-input" tabIndex="1" type="password" placeholder="إعادة كلمة المرور" onChange={(e) => handlePasswordChange('retryPassword', e.target.value)} />

                                        <div className='errors'>
                                            <div className='error-msg'>
                                                {passwordValidation['length'] ? <FaCheckCircle style={{ color: 'green', width: '16px', height: '16px', flex: 'none' }} /> :
                                                    <FontAwesomeIcon fontSize={16} color='#126ed0' icon={faCircleExclamation} style={{ flex: 'none' }} />}
                                                <span className='msg'> يجب أن تتكون كلمة المرور من 8 أحرف على الأقل. </span>
                                            </div>

                                            <div className='error-msg'>
                                                {passwordValidation['special'] ? <FaCheckCircle style={{ color: 'green', width: '16px', height: '16px', flex: 'none' }} /> :
                                                    <FontAwesomeIcon fontSize={16} color='#126ed0' icon={faCircleExclamation} style={{ flex: 'none' }} />}
                                                <span className='msg'> يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل. (!,@,#) </span>
                                            </div>

                                            <div className='error-msg'>
                                                {passwordValidation['match'] ? <FaCheckCircle style={{ color: 'green', width: '16px', height: '16px', flex: 'none' }} /> :
                                                    <FontAwesomeIcon fontSize={16} color='#126ed0' icon={faCircleExclamation} style={{ flex: 'none' }} />}
                                                <span className='msg'> كلمة المرور يجب ان تتطابق. </span>
                                            </div>



                                        </div>

                                        <button className="button button-green" type="button" onClick={resetPassword} disabled={!passwordValidation['length'] || !passwordValidation['match'] || !passwordValidation['special']}>إرسال</button>
                                    </div>
                                </div>)
                                :
                                (null)}
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ForgetPassword;
