import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import '../styles/header.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faArrowRightFromBracket, faBars, faUser, faGlobe, faTimes, faQuestion } from '@fortawesome/free-solid-svg-icons'
import Loading from './loading';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import arabic from "../assets/img/arabic.png";
import english from "../assets/img/english.jpg";
import french from "../assets/img/french.png";

const Header = ({ toggleMenu, menuOpen, home }) => {
    const navigate = useNavigate();

    let [loading, setLoading] = useState(false);

    let [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    useEffect(() => {
        const userStatus = Number(localStorage.getItem('status'));

        if (userStatus && userStatus >= 7) {
            setIsUserLoggedIn(true)
        }

    }, []);

    const logout = async () => {
        setLoading(true);

        const response = await fetch(`${process.env.REACT_APP_API}logout`, {
            method: "POST",
        });
        if (response.ok) {
            localStorage.removeItem("user")
            localStorage.removeItem('status')
            navigate("/login");
        }

        setLoading(false);
    };

    const navigateToThreads = () => {
        navigate("/thread");
    };

    return (
        <div style={{ width: '100%' }}>
            <Loading isLoading={loading} />

            <div className='header' style={{ backgroundColor: home ? 'transparent' : 'black', padding: home ? '30px 15px' : '10px 15px' }}>
                <div className='menu' style={{ display: home ? 'none' : 'initial' }}>
                    <FontAwesomeIcon style={{ fontSize: '18px', color: toggleMenu ? 'white' : 'transparent' }}
                        icon={menuOpen ? faTimes : faBars}
                        onClick={toggleMenu} />
                </div>

                <Link to={'/'}>
                    <div className='title'>
                        ecat
                    </div>
                </Link>

                <div className='actions'>
                    {isUserLoggedIn ?
                        (<Link to={"/profile"}>
                            <FontAwesomeIcon color='white' style={{ fontSize: '18px' }} icon={faUser} />
                        </Link>)
                        :
                        (<Link to={"/registration"}>
                            <FontAwesomeIcon color='white' style={{ fontSize: '18px' }} icon={faUserPlus} />
                        </Link>)
                    }

                    <div className='separator'></div>

                    <div className="dropdown languages" style={{ flex: 1 }}>
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FontAwesomeIcon color='white' style={{ fontSize: '18px' }} icon={faGlobe} />
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" > <img src={arabic} /> العربية </a>
                            <a className="dropdown-item" > <img src={english} /> English </a>
                            <a className="dropdown-item" > <img src={french} /> Français </a>
                        </div>
                    </div>

                    {isUserLoggedIn && <div className='separator'></div>}

                    {isUserLoggedIn && (<FontAwesomeIcon
                        icon={faQuestion}
                        onClick={navigateToThreads}
                        color="white"
                        style={{ fontSize: "18px", cursor: 'pointer' }}
                    />)}

                    {isUserLoggedIn && <div className='separator'></div>}

                    {isUserLoggedIn && (<FontAwesomeIcon
                        icon={faArrowRightFromBracket}
                        onClick={logout}
                        color="white"
                        style={{ fontSize: "18px", cursor: 'pointer' }}
                    />)}
                </div>


            </div>
        </div>

    );
};

export default Header;
