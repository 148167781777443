import React, { useState, useEffect } from "react";
import "./success.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useLocation, useNavigate } from 'react-router-dom';

import { Toaster } from "react-hot-toast"
import { faDownload } from '@fortawesome/free-solid-svg-icons'

import Loading from '../../components/loading';
import Header from "../../components/header";

const RegistrationSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [userImage, setUserImage] = useState(null);
  const [userPassportNumber, setUserPassportNumber] = useState('');

  const [bankCode, setBankCode] = useState('Bank Code');
  const [accountNumber, setAccountNumber] = useState('Bank Account Number');
  const [amount, setAmount] = useState('Amount');

  const [userID, setUserID] = useState('User ID');
  const [password, setPassword] = useState('Password');

  const [PDFURL, setPDFURL] = useState(null);

  useEffect(() => {
    const userStatus = Number(localStorage.getItem('status'));

    if (userStatus && userStatus === 4) {
      if (location.state) {
        setBankCode(location.state.bankCode)
        setAccountNumber(location.state.bankAccount)
        setAmount(location.state.amount)
        setUserID(location.state.username)
        setPassword(location.state.password)
        setUserName(location.state.name)
        setUserPassportNumber(location.state.passport_number)
        setUserImage(location.state.image)

        const sendEmail = async () => {
          setLoading(true)

          const response = await fetch(process.env.REACT_APP_API + 'sendBankInformation', {
            method: 'POST',
            body: JSON.stringify({
              bankAccount: location.state.bankAccount,
              bankCode: location.state.bankCode,
              amount: location.state.amount,
              password: location.state.password
            }),
          });

          if (response.ok) {
            const responseData = await response.json();

            if (responseData.message && responseData.message === "Bank information sent successfully") {
              setPDFURL(responseData.url)

              localStorage.removeItem('status')
              localStorage.removeItem('user')
            }
          }

          setLoading(false)
        };

        sendEmail()
      }

    } else {
      navigate('/');
    }
  }, []);

  const downloadPDF = () => {
    if (PDFURL) {
      window.open(PDFURL, '_blank');
    }
  };

  return (
    <main>
      <Toaster toastOptions={{ duration: 5000 }} position='bottom-center' />

      <Loading isLoading={loading} />

      <div className="registration">
        <Header />
      </div>
      <div className="registration-flow">
        <div className="heading">
          <div className="info ng-star-inserted">
            <div className="main-title">شكرا لك على الجهد والوقت.</div>
          </div>

          <div className="user-info">
            <img id="face-image" src={userImage} />

            <div className="user">
              <div className="name">
                {userName}
              </div>
              <div className="passport">
                {userPassportNumber}
              </div>
            </div>
          </div>

          <div className="sec-title">
            يرجى متابعة الدفع من خلال أقرب بنك مع التفاصيل التالية
          </div>

          <div className="card" style={{ textAlign: "center", direction: "ltr" }}>
            <div style={{ direction: "row", display: "flex" }}>
              <div className="card-header">رمز بنكي</div>
              <div className="card-header">رقم حساب</div>
              <div className="card-header" style={{ borderRight: "0" }}>
                المبلغ
              </div>
            </div>
            <div className="card" style={{ textAlign: "center", direction: "ltr" }}>
              <div style={{ direction: "row", display: "flex" }}>
                <div className="card-body">{bankCode}</div>
                <div className="card-body">{accountNumber}</div>
                <div className="card-body" style={{ borderRight: "0" }}>
                  {amount}$
                </div>
              </div>
            </div>
          </div>

        </div>

        <div>
          <div className="sec-title mt-4">
            يرجى الاحتفاظ ببيانات الاعتماد هذه معك لاستخدامها مع حسابك
          </div>
          <div className="mt-3" style={{ display: 'flex' }}>
            <div className="col">
              <label className="form-label">كلمة المرور</label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: "center" }}
                readOnly
                value={password}
              />
            </div>
            <div className="col">
              <label className="form-label">معرف المستخدم</label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: "center" }}
                readOnly
                value={userID}
              />
            </div>
          </div>

          <div className="buttons">
            <button className="button submit btn-primary mt-4" onClick={downloadPDF}>
              تحميل PDF
              <FontAwesomeIcon icon={faDownload} />
            </button>

            <button className="button submit button-green mt-4" onClick={() => navigate('/login')}>
              سجل دخولك الآن
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default RegistrationSuccess;
