import React from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Box from '@mui/material/Box';

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: 'translate(-50%, -50%)',
    zIndex: 100000
};

const Loading = ({ isLoading }) => {

    return (
        <main>
            <Box sx={{
                display: isLoading ? 'inital' : 'none',
                position: "fixed",
                height: '100%',
                width: '100%',
                zIndex: 10000,
            }}>
                <PuffLoader
                    color="#000"
                    loading={isLoading}
                    cssOverride={override}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </Box>
        </main>
    );
};

export default Loading;
