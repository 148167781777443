import React, { useState, forwardRef, useImperativeHandle } from 'react';
import "../registration/registration.css";

import not_blurred_pp from "./../../assets/img/not_blurred_pp.png";
import reflections_pp from "./../../assets/img/reflections_pp.png";
import not_cropped_pp from "./../../assets/img/not_cropped_pp.png";

import passportImage from "./../../assets/img/passport_image.JPG";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import imageCompression from 'browser-image-compression';

const RegistrationStepper3 = forwardRef((props, ref) => {
    const {
        setLoading,
        setLoadingProgress,
        setStepperValidation,
        formData,
        setFormData,
        navigate
    } = props;

    useImperativeHandle(ref, () => ({
        submitPassportInfo
    }));

    //  --------------- STEP 3 ----------------------

    const familyMembers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const familyMembersRelationships = ["أب", "أم", "أخ", "أخت", "زوج", "زوجة", "إبن", "إبنة"]

    const [passportImagesElements, setPassportImagesElements] = useState([]);
    const [familyMembersRelationship, setFamilyMembersRelationship] = useState([]);

    const submitPassportInfo = async () => {
        setLoadingProgress(true);

        const updatedFormData = { ...formData }
        updatedFormData.passportImages = passportImagesElements
        updatedFormData.relationships = familyMembersRelationship

        const response = await fetch(process.env.REACT_APP_API + 'submitPassportInfo', {
            method: 'POST',
            body: JSON.stringify(updatedFormData),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message']) {
                localStorage.setItem('status', 4);

                navigate('/registration-success', {
                    state: {
                        bankAccount: responseData['message']['bankAccount'],
                        bankCode: responseData['message']['bankCode'],
                        amount: responseData['message']['amount'],
                        username: responseData['message']['username'],
                        password: responseData['message']['password'],
                        name: responseData['message']['name'],
                        image: responseData['message']['image'],
                        passport_number: responseData['message']['passport_number'],
                    }
                });

            }
        }

        setLoadingProgress(false);
    };

    const handleInputChange = (fieldName, value) => {
        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                [fieldName]: value
            };

            const flag = Object.values(updatedFormData).every(fieldValue => fieldValue !== '');

            let passportArray = []
            let relationshipsArray = []

            if (fieldName === 'selectedFamilyMembers') {

                for (let i = 0; i < updatedFormData['selectedFamilyMembers']; i++) {
                    // Push a JSX element into the array
                    passportArray.push('');
                    relationshipsArray.push('');
                }

                setPassportImagesElements(passportArray)
                setFamilyMembersRelationship(relationshipsArray)
            } else {
                passportArray = passportImagesElements
                relationshipsArray = familyMembersRelationship
            }

            setStepperValidation(prevState => ({
                ...prevState,
                3: flag && passportArray.every(img => img !== '') && relationshipsArray.every(relationship => relationship !== '')
            }));

            return updatedFormData;
        });
    };

    const handleInputChangeFamilyMembersRelationship = (index, value) => {
        const newArray = [...familyMembersRelationship];
        newArray[index] = value;
        setFamilyMembersRelationship(newArray);

        const flag = Object.values(formData).every(fieldValue => fieldValue !== '');
        const passport = passportImagesElements.every(img => img !== '');
        const relationships = newArray.every(img => img !== '');

        if (flag && passport && relationships) {
            setStepperValidation(prevState => ({
                ...prevState,
                3: true
            }));
        }
    }

    const handleFileChangePassportFamilyMember = async (event, index) => {
        const file = event.target.files[0]; // Get the selected file

        if (file) {
            try {
                setLoading(true)

                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }

                const compressedFile = await imageCompression(file, options);

                const reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onloadend = () => {
                    const newArray = [...passportImagesElements];
                    newArray[index] = reader.result;
                    setPassportImagesElements(newArray);

                    const flag = Object.values(formData).every(fieldValue => fieldValue !== '');
                    const passport = newArray.every(img => img !== '');
                    const relationships = familyMembersRelationship.every(img => img !== '');

                    if (flag && passport && relationships) {
                        setStepperValidation(prevState => ({
                            ...prevState,
                            3: true
                        }));
                    }

                    setLoading(false)
                };
            } catch (error) {
                setLoading(false)
            }
        }
    };

    return (
        <div className="inner-content">
            <div className="main-title form-title">بيانات جواز السفر</div>
            <div className="sec-title"> يرجى التأكد من صحة جميع معلومات جواز السفر. </div>

            <form autoComplete="off" className="mb-4" noValidate>
                <div className='verify-phone'>
                    <label className="main-label">الإسم</label>
                    <input value={formData['userName']} className="main-input" tabIndex="1" type="text" onChange={(e) => handleInputChange('userName', e.target.value)} />

                    <label className="main-label">الجنسية</label>
                    <input value={formData['nationality']} className="main-input" tabIndex="1" type="text" onChange={(e) => handleInputChange('nationality', e.target.value)} />

                    <label className="main-label">العمر</label>
                    <input value={formData['age']} className="main-input" tabIndex="2" type="number" onChange={(e) => handleInputChange('age', e.target.value)} />

                    <label className="main-label">النوع</label>
                    <input value={formData['gender']} className="main-input" tabIndex="3" type="text" onChange={(e) => handleInputChange('gender', e.target.value)} />

                    <label className="main-label">رقم جواز السفر</label>
                    <input value={formData['passportNumber']} className="main-input" tabIndex="4" type="text" onChange={(e) => handleInputChange('passportNumber', e.target.value)} />

                    <label className="main-label">تاريخ الإنتهاء</label>
                    <DatePicker selected={formData['expirationDate']} tabIndex="5" dateFormat="yyyy-MM-dd" onChange={(date) => handleInputChange('expirationDate', date ? date.toISOString().split('T')[0] : '')} />

                    <label className="main-label">خدمة التسجيل الرئيسية</label>

                    <input
                        type="radio"
                        checked={formData['mainService'] === 'الجنسية'}
                        onChange={() => handleInputChange('mainService', 'الجنسية')}
                        tabIndex="6"
                    />
                    <label className="options-label">الجنسية</label>

                    <input
                        type="radio"
                        checked={formData['mainService'] === 'الإقامة'}
                        onChange={() => handleInputChange('mainService', 'الإقامة')}
                        tabIndex="7"
                    />

                    <label className="options-label">الإقامة</label>

                    <label className="main-label">عدد أفراد الأسرة</label>

                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {formData['selectedFamilyMembers'] === '' ? 'إختر عدد أفراد الأسرة' : formData['selectedFamilyMembers']}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {familyMembers.map((item, index) => (
                                <a key={index} className="dropdown-item" onClick={() => handleInputChange('selectedFamilyMembers', item)}>{item}</a>
                            ))}

                        </div>
                    </div>

                    {passportImagesElements.map((item, index) => (
                        <div className='upload-images' key={'upload-images-' + Math.floor(Math.random() * 10000) + 1 + index}
                            id={'upload-images-' + Math.floor(Math.random() * 10000) + 1 + index}>

                            <div className='each-upload'>

                                <div className="img-title">
                                    <img src="passport_4774004.png" alt="Image" style={{ width: '50px', height: '50px' }} />
                                    <p>التقط صورة لجواز سفر فرد عائلتك {index + 1} و حدد صلة القرابة</p>
                                </div>

                                <div style={{ margin: '40px 10px', display: 'flex', justifyContent: 'center' }} className="dropdown">
                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {familyMembersRelationship[index] === '' ? 'إختر صلة القرابة' : familyMembersRelationship[index]}
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {familyMembersRelationships.map((item, i) => (
                                            <a key={i} className="dropdown-item" onClick={() => handleInputChangeFamilyMembersRelationship(index, item)}>{item}</a>
                                        ))}

                                    </div>
                                </div>

                                <div style={{ margin: '40px 10px', display: 'flex', justifyContent: 'center' }}>
                                    <img src={item == '' ? passportImage : item}
                                        style={{ borderRadius: '20px', maxWidth: "-webkit-fill-available", maxHeight: "350px" }}
                                    />
                                </div>

                                <div className='tips-title'>
                                    -اتبع النصائح التالية:

                                    <div className='tips'>
                                        <div className='each-tip'>
                                            <img src={not_cropped_pp} />
                                            غير مقصوصة

                                        </div>

                                        <div className='each-tip'>
                                            <img src={not_blurred_pp} />
                                            ليس واضحا

                                        </div>


                                        <div className='each-tip'>
                                            <img src={reflections_pp} />
                                            لا انعكاسات
                                        </div>
                                    </div>
                                </div>

                                <label className="file-label button button-blue" style={{ 'margin': '40px auto' }}>
                                    {item !== '' ? "إعادة التقاط الصورة" : "بدء تشغيل الكاميرا"}
                                    <input accept="image/jpeg, image/png" className="file-input" type="file" capture="environment" style={{ "display": 'none' }} onChange={(event) => handleFileChangePassportFamilyMember(event, index)} />
                                </label>

                            </div>

                        </div>
                    ))}

                </div>

            </form>
        </div>
    )
});

export default RegistrationStepper3;
