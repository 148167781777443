import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./forget-id.css";

import toast, { Toaster } from "react-hot-toast"
import PhoneInput from "react-phone-input-2";

import Loading from '../../components/loading';
import Header from "../../components/header";

const ForgetID = () => {
    const navigate = useNavigate();

    let [loading, setLoading] = useState(false);

    const [phone, setPhone] = useState('')
    const [sendButton, setSendButton] = useState(true)

    useEffect(() => {
        const userStatus = Number(localStorage.getItem('status'));

        if (userStatus) {
            navigate('/');
        }
    }, []);

    const handlePhoneNumberChange = (phone) => {
        setPhone(phone);
        setSendButton(false)
    };

    const onClickSubmit = async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_API + 'forgetID', {
            method: 'POST',
            body: JSON.stringify({
                "phone": phone
            }),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message'] === "ECAT ID sent") {
                navigate('/login', { state: { message: "تم إرسال طلبك بنجاح!" } });
            } else {
                toast.error("لقد حدث خطأ ما!")
            }
        }

        setLoading(false);
    };

    return (
        <main>
            <Toaster toastOptions={{ duration: 5000 }} position='bottom-center' />

            <Loading isLoading={loading} />

            <div className='forget-id'>
                <Header />

                <div className='forget-id-flow'>
                    <div className="heading">
                        <div className="info">
                            <div className="main-title">نسيت رقم حساب ecat؟</div>
                            <div className="sec-title">أدخل جميع البيانات المطلوبة في الحقول المميزة بالعلامة (*).
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content login">
                    <div className="inner-content">
                        <form noValidate="" className="ng-untouched ng-pristine ng-invalid">
                            <label className="main-label">رقم الهاتف *</label>
                            <div className="input-holder">
                                <PhoneInput
                                    value={phone}
                                    preferredCountries={['eg']}
                                    placeholder='+20 1XX XXXX XXX'
                                    country={"eg"}
                                    onChange={phone => handlePhoneNumberChange(phone)}
                                />
                            </div>

                            <button className="button button-green" type="button" onClick={onClickSubmit} disabled={sendButton}>إرسال</button>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ForgetID;
