import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import AuthInterceptor, { setNavigateHandler } from './services/AuthInterceptor';

import { GoogleOAuthProvider } from '@react-oauth/google';

const store = configureStore({
  reducer: {
  },
});

setupListeners(store.dispatch);

const InitializeInterceptor = () => {
  const navigate = useNavigate();
  setNavigateHandler(navigate); // Set the navigation handler

  return null;
};

// Initialize AuthInterceptor once here
AuthInterceptor();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <GoogleOAuthProvider clientId="464338817265-q2ktv3giaeuhe51g3boqtfitho5pslva.apps.googleusercontent.com">
    <Provider store={store}>
      <BrowserRouter>
        <InitializeInterceptor />
        <App />
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>

);