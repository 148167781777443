import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./home.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

import toast, { Toaster } from "react-hot-toast"

import { useNavigate } from "react-router-dom";

import Loading from '../../components/loading';
import Header from "../../components/header";
import CoursesProgress from "../../components/courses-progress";


const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let [isUserLoggedIn, setIsUserLoggedIn] = useState('');
  let [loading, setLoading] = useState(false);

  let [courses, setCourses] = useState([]);
  let [certificateLink, setCertificateLink] = useState(null);
  let [hasCertificate, setHasCertificate] = useState(false);

  useEffect(() => {
    const userStatus = Number(localStorage.getItem('status'));


    if (userStatus && userStatus >= 7) {
      if (location.state && location.state.message) {
        toast.success(location.state.message)
      }

      setIsUserLoggedIn(true)

      const getHome = async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_API + 'getHome', { method: 'GET' });

        if (response.ok) {
          const responseData = await response.json();

          if (responseData['courses']) {
            setCourses(responseData['courses'])
            setCertificateLink(responseData['certificate_link'])

            // Check if all courses have progress percentage of 100
            const allCompleted = responseData['courses'].every(course => course.progress_percentage === 100);
            // Update hasCertificate state accordingly
            setHasCertificate(allCompleted);
          } else {
            toast.error("لقد حدث خطأ ما!")
          }
        }

        setLoading(false);
      };

      getHome()
    } else {
      localStorage.removeItem('user')
      localStorage.removeItem('status')
    }

  }, []);


  return (
    <main>
      <Toaster toastOptions={{ duration: 5000 }} position='bottom-center' />

      <Loading isLoading={loading} />

      <div className="home">
        <Header home />

        <div className="home-titles">
          <div className="first">
            {isUserLoggedIn ? 'ابدأ التدريبات' : 'مسار الإقامة للوافدين في مصر'}
          </div>

          <div className="second">
            {isUserLoggedIn ? 'بعد الانتهاء من الدورات، ستتلقى الشهادة.' : 'تدريب الوافدين المتقدمين للحصول على الإقامة أو الجنسية المصرية علي اساسيات المواطنة و اللغة العربية.'}

          </div>
        </div>

        {
          isUserLoggedIn ?
            (<CoursesProgress
              courses={courses}
              navigate={navigate}
              hasCertificate={hasCertificate}
              certificateLink={certificateLink}
              setCertificateLink={setCertificateLink}
              setLoading={setLoading}
              opacity={0.5} />) :
            (<div className="login-register">
              <Link to={"/login"}>
                <button
                  className="button button-transparent login width-auto"
                  id="btn_login"
                  tabIndex="0"
                >
                  تسجيل دخول
                </button>
              </Link>

              <span>أو</span>

              <Link to={"/registration"}>
                <button
                  className="button button-green register width-auto"
                  id="btn_register"
                  tabIndex="0"
                >
                  <FontAwesomeIcon icon={faUserPlus} />
                  <span>اشترك الآن!</span>
                </button>
              </Link>
            </div>
            )
        }


        <div className="copy-rights">
          © 2024 جميع الحقوق محفوظة .ecat
          <br></br>
          patron. التطوير والتشغيل من قبل مجموعة
        </div>
      </div>
    </main>
  );
};

export default Home;
