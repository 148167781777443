import React from "react";
import "../styles/courses-progress.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FaCheckCircle } from "react-icons/fa";

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CoursesProgress = ({ courses, navigate, hasCertificate, certificateLink, setCertificateLink, setLoading, opacity, className }) => {
    const buttonColors = [
        'rgb(0, 100, 0)',
        'rgb(255, 140, 0)',
        'rgb(17, 0, 255)'
    ]

    const getColorWithOpacity = (color, opacity) => {
        // Convert the base color to RGBA
        return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
    };

    const NavCourseDetails = (index, progress) => {
        if (progress < 100) {
            navigate('/course-details', { state: { course_id: index, course_progress: progress } });
        }
    };

    const onClickCertificate = () => {
        if (certificateLink) {
            window.open(certificateLink, '_blank');
        } else {
            downloadCertificate()
        }
    };

    const downloadCertificate = async () => {
        setLoading(true);

        const response = await fetch(process.env.REACT_APP_API + 'downloadCertificate', { method: 'GET' });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message'] == 'Certificate generated successfully!') {
                setCertificateLink(responseData['url'])
                window.open(responseData['url'], '_blank');
            }
        }

        setLoading(false);
    };

    return (
        <div className={className + ' course-button'}>

            {courses.map((course, index) => (
                <button key={index} tabIndex="0" onClick={() => NavCourseDetails(course.course_id, course.progress_percentage)} style={{ background: getColorWithOpacity(buttonColors[index], opacity) }}>
                    {course.name}

                    {course.progress_percentage === 100
                        ?
                        <FaCheckCircle style={{ color: 'green', width: '25px', height: '25px', flex: 'none' }} />
                        :
                        <div style={{ width: 50, height: 50, flex: 'none' }}>
                            <CircularProgressbar
                                strokeWidth={8}
                                value={Math.round(course.progress_percentage)}
                                text={`${Math.round(course.progress_percentage)}%`}
                                styles={buildStyles({
                                    textSize: '28px',
                                    pathTransitionDuration: 0.5,
                                    pathColor: `rgba(0, 0, 0)`,
                                    textColor: 'rgb(221 221 221)',
                                })}
                            />
                        </div>
                    }

                </button>
            ))}

            <div onClick={onClickCertificate} className='certificate' style={{ display: hasCertificate ? 'flex' : 'none' }}>

                تحميل الشهادة
                <FontAwesomeIcon icon={faDownload} />
            </div>

        </div>
    );
};

export default CoursesProgress;