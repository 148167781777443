import React, { useState, useEffect } from "react";
import "./profile.css";

import { useLocation, useNavigate } from 'react-router-dom';

import toast, { Toaster } from "react-hot-toast"

import profile_pic from "./../../assets/img/profile-pic.png";

import Loading from '../../components/loading';
import Header from "../../components/header";
import CoursesProgress from "../../components/courses-progress";
import FamilyMemberCard from "../../components/family-member-card";

const Profile = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    let [courses, setCourses] = useState([]);
    let [certificateLink, setCertificateLink] = useState(null);
    let [hasCertificate, setHasCertificate] = useState(false);

    const [profileData, setProfileData] = useState({
        phone: 'رقم الهاتف المحمول',
        full_name: 'اسم المستخدم',
        email: 'البريد الإلكتروني',
        face_image: '',
        service_type: '',
        nationality: 'الجنسية',
        age: 'العمر',
        Gender: 'النوع',
        passport_number: 'رقم جواز السفر',
        expiration_date: 'تاريخ الإنتهاء',
        user_reference_number: '#### - ### - ###',
        certificate: '',
        receipt_url: '',
        family_members_number: '',
        certificate_date: '',
        created_at: '',
        updated_at: ''
    });

    useEffect(() => {
        const getProfile = async () => {
            setLoading(true);

            const response = await fetch(process.env.REACT_APP_API + 'getProfile', { method: 'GET' });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData['user']) {
                    setProfileData(responseData['user'])
                    setCourses(responseData['user']['courses'])
                    setCertificateLink(responseData['user']['certificate_link'])

                    // Check if all courses have progress percentage of 100
                    const allCompleted = responseData['user']['courses'].every(course => course.progress_percentage === 100);
                    // Update hasCertificate state accordingly
                    setHasCertificate(allCompleted);

                    handleData(responseData)
                } else {
                    toast.error("لقد حدث خطأ ما!")
                }
            }

            setLoading(false);
        };

        getProfile()

    }, []);

    const handleData = (responseData) => {
        let url = responseData['user']['survey'][0]['social_media_links']

        for (const link of socialMediaLinks) {
            if (url.startsWith(link)) {
                const username = url.slice(link.length);

                setSocialMediaLinkValue(link)
                responseData['user']['survey'][0]['social_media_links'] = username
                setFormData(responseData['user']['survey'][0])
                break;
            }
        }

    }

    let [stepperValidation, setStepperValidation] = useState(false);

    const motherLanguages = [
        'العربية',
        'الإنجليزية',
        'الصينية',
        'الهندية',
        'الإسبانية',
        'الروسية',
        'البرتغالية',
        'البنغالية',
        'الفرنسية',
        'الألمانية',
        'أخرى'
    ]

    const governorate = [
        "القاهرة",
        "الجيزة",
        "الإسكندرية",
        "الدقهلية",
        "البحر الأحمر",
        "البحيرة",
        "الفيوم",
        "الغربية",
        "الإسماعيلية",
        "المنوفية",
        "المنيا",
        "القليوبية",
        "الوادي الجديد",
        "السويس",
        "أسوان",
        "أسيوط",
        "بني سويف",
        "بورسعيد",
        "دمياط",
        "الشرقية",
        "جنوب سيناء",
        "كفر الشيخ",
        "مطروح",
        "الأقصر",
        "قنا",
        "شمال سيناء",
        "سوهاج"
    ];

    const educationalDegree = [
        'شهادة التعليم الابتدائي',
        'شهادة التعليم الإعدادي',
        'الثانوية العامة',
        'دبلوم',
        'شهادة تقنية',
        'البكالوريوس',
        'الماجستير',
        'الدكتوراه'
    ]

    const socialMediaLinks = [
        'https://www.facebook.com/',
        'https://www.instagram.com/',
        'https://www.tiktok.com/',
    ]

    const [socialMediaLinkValue, setSocialMediaLinkValue] = useState('https://www.facebook.com/');


    const [formData, setFormData] = useState({
        mother_language: 'اللغة الأم',
        residence: '',
        social_media_links: '',
        highest_education_level: '',
        current_job: ''
    });

    const handleInputChange = (fieldName, value) => {
        setFormData(prevFormData => {
            if (fieldName === 'socialMediaLink') {
                value = socialMediaLinkValue + value
            }

            const updatedFormData = {
                ...prevFormData,
                [fieldName]: value
            };

            const flag = Object.values(updatedFormData).every(fieldValue => fieldValue !== '');

            setStepperValidation(flag);

            return updatedFormData;
        });
    };

    const updateProfile = async () => {
        setLoading(true);

        const updatedFormData = {
            ...formData,
            ['social_media_links']: socialMediaLinkValue + formData['social_media_links']
        };

        const response = await fetch(process.env.REACT_APP_API + 'updateProfile', { method: 'PUT', body: JSON.stringify(updatedFormData) });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message'] === 'Profile updated successfully') {
                navigate('/', { state: { message: "تم تحديث الملف الشخصي بنجاح." } });
            } else {
                toast.error("لقد حدث خطأ ما!")
            }
        }

        setLoading(false);
    };

    return (
        <main>
            <Toaster toastOptions={{ duration: 5000 }} position='bottom-center' />

            <Loading isLoading={loading} />

            <div className="profile">
                <Header />

                <div className="user-nav">
                    <div className="image-holder">
                        <img src={profileData['face_image'] === '' ? profile_pic : profileData['face_image']} />
                    </div>

                    <div className="info">
                        <div className="welcome">مرحبا</div>
                        <div className="name">{profileData['full_name']}</div>
                        <div className="id"> رقم حساب: <span className="id-num">{profileData['user_reference_number']}</span> </div>

                    </div>

                </div>

                <div className="flex flex-row flex-wrap profile-info gap-6">
                    <div className="grow basis-3/5 white-container size-fit">
                        <div className="page-title">البيانات الشخصية</div>

                        <label className="main-label">اسم المستخدم</label>
                        <input className="main-input" readOnly readonlyvalue="true" disabled value={profileData['full_name']} />

                        <label className="main-label">رقم الهاتف المحمول</label>
                        <input className="main-input" readOnly readonlyvalue="true" disabled value={profileData['phone']} />

                        <label className="main-label">البريد الإلكتروني</label>
                        <input className="main-input" readOnly readonlyvalue="true" disabled value={profileData['email']} />

                        <label className="main-label">الجنسية</label>
                        <input className="main-input" readOnly readonlyvalue="true" disabled value={profileData['nationality']} />

                        <label className="main-label">العمر</label>
                        <input className="main-input" readOnly readonlyvalue="true" disabled value={profileData['age']} />

                        <label className="main-label">النوع</label>
                        <input className="main-input" readOnly readonlyvalue="true" disabled value={profileData['Gender']} />

                        <label className="main-label">رقم جواز السفر</label>
                        <input className="main-input" readOnly readonlyvalue="true" disabled value={profileData['passport_number']} />

                        <label className="main-label">تاريخ الإنتهاء</label>
                        <input className="main-input" readOnly readonlyvalue="true" disabled value={profileData['expiration_date']} />


                        <label className="main-label">اللغة الأم</label>
                        <input className="main-input" readOnly readonlyvalue="true" disabled value={formData['mother_language']} />

                        <label className="main-label">محل الإقامة</label>

                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {formData['residence'] === '' ? 'إختر محل الإقامة' : formData['residence']}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {governorate.map((item, index) => (
                                    <a key={index} className="dropdown-item" onClick={() => handleInputChange('residence', item)}>{item}</a>
                                ))}

                            </div>
                        </div>

                        <label className="main-label">روابط وسائل التواصل الاجتماعي</label>

                        <div className="socail-media-link">
                            <div className="dropdown" style={{ flex: 1 }}>
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {socialMediaLinkValue}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {socialMediaLinks.map((item, index) => (
                                        <a key={index} className="dropdown-item" onClick={() => setSocialMediaLinkValue(item)}>{item}</a>
                                    ))}

                                </div>
                            </div>

                            <input className="main-input" style={{ flex: 1 }} tabIndex="1" type="text" placeholder="إسم المستخدم" value={formData['social_media_links']} onChange={(e) => handleInputChange('social_media_links', e.target.value)} />

                        </div>

                        <label className="main-label">أعلى مستوى تعليمي</label>

                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {formData['highest_education_level'] === '' ? 'إختر أعلى مستوى تعليمي' : formData['highest_education_level']}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {educationalDegree.map((item, index) => (
                                    <a key={index} className="dropdown-item" onClick={() => handleInputChange('highest_education_level', item)}>{item}</a>
                                ))}

                            </div>
                        </div>

                        <label className="main-label">الوظيفة</label>
                        <input className="main-input" tabIndex="1" type="text" placeholder="(مثال: مهندس مدني)" value={formData['current_job']} onChange={(e) => handleInputChange('current_job', e.target.value)} />


                        <button onClick={updateProfile} className="button submit button-green mt-4" id="btn_proceed" tabIndex="11" type="button" disabled={!stepperValidation}> تحديث الملف الشخصي </button>

                    </div>
                    <div className="grow basis-1/4 size-fit flex flex-col gap-6">

                        <div className="white-container flex flex-col items-center">
                            <div className="page-title text-start">تقدم الدورات</div>

                            {profileData['courses']?.length > 0 ? (
                                <div className="flex flex-col items-center">
                                    <CoursesProgress
                                        className="mt-4"
                                        courses={courses}
                                        navigate={navigate}
                                        hasCertificate={hasCertificate}
                                        certificateLink={certificateLink}
                                        setCertificateLink={setCertificateLink}
                                        setLoading={setLoading}
                                        opacity={1} />
                                </div>
                            ) : (
                                <div className="text-center my-20">لا توجد دورات.</div>
                            )}


                        </div>

                        <div className="white-container">
                            <div className="page-title">بيانات أفراد العائلة</div>

                            {profileData['family_members']?.length > 0 ? (
                                <div className="flex flex-col items-center">
                                    {profileData.family_members.map((member, index) => (
                                        <FamilyMemberCard
                                            key={index}
                                            fullName={member.full_name}
                                            passportImage={member.passport_image}
                                            nationality={member.nationality}
                                            age={member.age}
                                            gender={member.Gender}
                                            passportNumber={member.passport_number}
                                            relationship={member.relationship}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div className="text-center my-20">لا يوجد أفراد عائلة.</div>
                            )}

                        </div>

                    </div>
                </div>


            </div>

        </main>
    );
};

export default Profile;