import fetchIntercept from 'fetch-intercept';
import toast from "react-hot-toast";

let navigateHandler = null;

export const setNavigateHandler = (navigate) => {
    navigateHandler = navigate;
};

const AuthInterceptor = () => {
    fetchIntercept.register({
        request: function (url, config) {

            if (!config.headers) {
                config.headers = {};
            }

            config.headers['Content-Type'] = 'application/json';

            const userJSON = localStorage.getItem('user');

            if (userJSON) {
                const user = JSON.parse(userJSON);

                if (user && user['api_token']) {
                    config.headers['Authorization'] = 'Bearer ' + user['api_token'];
                }
            }

            return [url, config];
        },

        requestError: function (error) {
            return Promise.reject(error);
        },

        response: function (response) {
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('user');
                    localStorage.removeItem('status')

                    if (navigateHandler) {
                        navigateHandler('/login', { state: { error: "انتهت الجلسة، من فضلك سجل دخولك للمتابعة" } });
                    }
                } else {
                    response.clone().json().then(data => {
                        if (data['error']) {
                            toast.error(data['error']);
                        } else {
                            toast.error("لقد حدث خطأ ما!");
                        }
                    }).catch(() => {
                        response.text().then(_ => {
                            toast.error("لقد حدث خطأ ما!");
                        }).catch(() => {
                            toast.error("لقد حدث خطأ ما!");
                        });
                    });
                }
            }
            return response;
        },

        responseError: function (error) {
            return Promise.reject(error);
        }
    });
};

export default AuthInterceptor;