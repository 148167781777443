import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import "../registration/registration.css";

import not_blurred from "./../../assets/img/not_blurred.png";
import reflections from "./../../assets/img/reflections.png";
import not_cropped from "./../../assets/img/not_cropped.png";

import not_blurred_pp from "./../../assets/img/not_blurred_pp.png";
import reflections_pp from "./../../assets/img/reflections_pp.png";
import not_cropped_pp from "./../../assets/img/not_cropped_pp.png";

import forward from "./../../assets/img/forward.jpg";
import right from "./../../assets/img/right.jpg";
import left from "./../../assets/img/left.jpg";

import passportImage from "./../../assets/img/passport_image.JPG";

import imageCompression from 'browser-image-compression';

const RegistrationStepper2 = forwardRef((props, ref) => {
    const {
        setLoading,
        setLoadingProgress,
        stepper,
        setStepper,
        setStepperValidation,
        setFormData,
        isStepperValidated
    } = props;

    useImperativeHandle(ref, () => ({
        uploadFacePassport
    }));

    //  --------------- STEP 2 ----------------------

    let [imageFace, setImageFace] = useState(null);

    const handleFileChangeFace = async (event) => {
        const file = event.target.files[0]; // Get the selected file

        if (file) {
            // console.log('originalFile instanceof Blob', file instanceof Blob); // true
            // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

            const options = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }

            try {
                setLoading(true)

                const compressedFile = await imageCompression(file, options);
                // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                const reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onloadend = () => {
                    setImageFace(reader.result);

                    if (imagePassport && imageFaceRight && imageFaceLeft) {
                        setStepperValidation(prevState => ({
                            ...prevState,
                            2: true
                        }));
                    }

                    setLoading(false)
                    scrollToDiv()
                };
            } catch (error) {
                setLoading(false)
            }

        }
    };

    let [imageFaceLeft, setImageFaceLeft] = useState(null);

    const handleFileChangeFaceLeft = async (event) => {
        const file = event.target.files[0]; // Get the selected file

        if (file) {
            // console.log('originalFile instanceof Blob', file instanceof Blob); // true
            // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

            const options = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }

            try {
                setLoading(true)

                const compressedFile = await imageCompression(file, options);
                // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                const reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onloadend = () => {
                    setImageFaceLeft(reader.result);

                    if (imagePassport && imageFace && imageFaceRight) {
                        setStepperValidation(prevState => ({
                            ...prevState,
                            2: true
                        }));
                    }

                    setLoading(false)
                    scrollToDiv()
                };
            } catch (error) {
                setLoading(false)
            }

        }
    };

    let [imageFaceRight, setImageFaceRight] = useState(null);

    const handleFileChangeFaceRight = async (event) => {
        const file = event.target.files[0]; // Get the selected file

        if (file) {
            // console.log('originalFile instanceof Blob', file instanceof Blob); // true
            // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

            const options = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }

            try {
                setLoading(true)

                const compressedFile = await imageCompression(file, options);
                // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                const reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onloadend = () => {
                    setImageFaceRight(reader.result);

                    if (imagePassport && imageFace && imageFaceLeft) {
                        setStepperValidation(prevState => ({
                            ...prevState,
                            2: true
                        }));
                    }

                    setLoading(false)
                    scrollToDiv()
                };
            } catch (error) {
                setLoading(false)
            }

        }
    };

    let [imagePassport, setImagePassport] = useState(null);

    const handleFileChangePassport = async (event) => {
        const file = event.target.files[0]; // Get the selected file

        if (file) {
            // console.log('originalFile instanceof Blob', file instanceof Blob); // true
            // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }

            try {
                setLoading(true)

                const compressedFile = await imageCompression(file, options);
                // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                const reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onloadend = () => {
                    setImagePassport(reader.result);

                    if (imageFace && imageFaceRight && imageFaceLeft) {
                        setStepperValidation(prevState => ({
                            ...prevState,
                            2: true
                        }));
                    }

                    setLoading(false)
                    scrollToDiv()
                };
            } catch (error) {
                setLoading(false)
            }

        }
    };

    const uploadFacePassport = async () => {
        setLoadingProgress(true);

        const response = await fetch(process.env.REACT_APP_API + 'uploadFacePassport', {
            method: 'POST',
            body: JSON.stringify({
                faceimage: imageFace,
                firstLookLeft: imageFaceLeft,
                firstLookRight: imageFaceRight,
                passportimage: imagePassport
            }),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message']) {
                setFormData(prevState => ({
                    ...prevState,
                    userName: responseData['message']['name'],
                    nationality: responseData['message']['nationality'],
                    age: responseData['message']['age'],
                    gender: responseData['message']['gender'],
                    passportNumber: responseData['message']['passportNumber'],
                    expirationDate: responseData['message']['expirationDate'],
                }));

                setStepper(stepper + 1)
                window.scrollTo(0, 0);

                localStorage.setItem('status', 3);
            }
        }

        setLoadingProgress(false);
    };

    const targetDivRef = useRef(null);

    const scrollToDiv = () => {
        if (targetDivRef.current) {
            targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div ref={targetDivRef} className='inner-content'>

            <div className="main-title form-title">المستندات</div>
            <div className="sec-title">تأكد من إدخال البيانات المطلوبة.</div>

            <div className='upload-images'>

                {/* FACE 1 */}

                {((!imageFace) || isStepperValidated) && (
                    <div className='each-upload'>

                        <div className="img-title">
                            <img src="images.png" alt="Image" style={{ width: '50px', height: '46px' }} />
                            <div className='title'>
                                <p>التقط صورة لوجهك.</p>
                                <span> انظر بشكل مستقيم (1 من 3) </span>
                            </div>
                        </div>

                        <div style={{ margin: '40px 10px', display: 'flex', justifyContent: 'center' }}>
                            <img src={imageFace == null ? forward : imageFace}
                                style={{ borderRadius: '20px', maxWidth: "-webkit-fill-available", maxHeight: "250px" }}
                            />
                        </div>

                        <div className='tips-title'>
                            -اتبع النصائح التالية:

                            <div className='tips'>
                                <div className='each-tip'>
                                    <img src={not_cropped} />
                                    غير مقصوصة

                                </div>

                                <div className='each-tip'>
                                    <img src={not_blurred} />
                                    ليس واضحا

                                </div>


                                <div className='each-tip'>
                                    <img src={reflections} />
                                    لا انعكاسات
                                </div>
                            </div>
                        </div>

                        <label className="file-label button button-blue" style={{ 'margin': '40px auto' }}>
                            {imageFace ? "إعادة التقاط الصورة" : "بدء تشغيل الكاميرا"}
                            <input accept="image/jpeg, image/png" className="file-input" type="file" capture="user" style={{ "display": 'none' }} onChange={handleFileChangeFace} />
                        </label>


                    </div>
                )}

                {/* FACE 2 */}

                {((!imageFaceLeft && imageFace) || isStepperValidated) && (
                    <div className='each-upload'>

                        <div className="img-title">
                            <img src="images.png" alt="Image" style={{ width: '50px', height: '46px' }} />
                            <div className='title'>
                                <p>التقط صورة لوجهك.</p>
                                <span> انظر إلى اليسار (2 من 3) </span>
                            </div>
                        </div>

                        <div style={{ margin: '40px 10px', display: 'flex', justifyContent: 'center' }}>
                            <img src={imageFaceLeft == null ? left : imageFaceLeft}
                                style={{ borderRadius: '20px', maxWidth: "-webkit-fill-available", maxHeight: "250px" }}
                            />
                        </div>

                        <div className='tips-title'>
                            -اتبع النصائح التالية:

                            <div className='tips'>
                                <div className='each-tip'>
                                    <img src={not_cropped} />
                                    غير مقصوصة

                                </div>

                                <div className='each-tip'>
                                    <img src={not_blurred} />
                                    ليس واضحا

                                </div>


                                <div className='each-tip'>
                                    <img src={reflections} />
                                    لا انعكاسات
                                </div>
                            </div>
                        </div>

                        <label className="file-label button button-blue" style={{ 'margin': '40px auto' }}>
                            {imageFaceLeft ? "إعادة التقاط الصورة" : "بدء تشغيل الكاميرا"}
                            <input accept="image/jpeg, image/png" className="file-input" type="file" capture="user" style={{ "display": 'none' }} onChange={handleFileChangeFaceLeft} />
                        </label>

                    </div>
                )}


                {/* FACE 3 */}

                {((!imageFaceRight && imageFace && imageFaceLeft) || isStepperValidated) && (
                    <div className='each-upload'>

                        <div className="img-title">
                            <img src="images.png" alt="Image" style={{ width: '50px', height: '46px' }} />
                            <div className='title'>
                                <p>التقط صورة لوجهك.</p>
                                <span> انظر إلى اليمين (3 من 3) </span>
                            </div>
                        </div>

                        <div style={{ margin: '40px 10px', display: 'flex', justifyContent: 'center' }}>
                            <img src={imageFaceRight == null ? right : imageFaceRight}
                                style={{ borderRadius: '20px', maxWidth: "-webkit-fill-available", maxHeight: "250px" }}
                            />
                        </div>

                        <div className='tips-title'>
                            -اتبع النصائح التالية:

                            <div className='tips'>
                                <div className='each-tip'>
                                    <img src={not_cropped} />
                                    غير مقصوصة

                                </div>

                                <div className='each-tip'>
                                    <img src={not_blurred} />
                                    ليس واضحا

                                </div>


                                <div className='each-tip'>
                                    <img src={reflections} />
                                    لا انعكاسات
                                </div>
                            </div>
                        </div>

                        <label className="file-label button button-blue" style={{ 'margin': '40px auto' }}>
                            {imageFaceRight ? "إعادة التقاط الصورة" : "بدء تشغيل الكاميرا"}
                            <input accept="image/jpeg, image/png" className="file-input" type="file" capture="user" style={{ "display": 'none' }} onChange={handleFileChangeFaceRight} />
                        </label>

                    </div>
                )}


                {/* PASSPORT */}

                {((!imagePassport && imageFace && imageFaceRight && imageFaceLeft) || isStepperValidated) && (
                    <div className='each-upload'>

                        <div className="img-title">
                            <img src="passport_4774004.png" alt="Image" style={{ width: '50px', height: '50px' }} />
                            <p>التقط صورة لجواز سفرك</p>
                        </div>

                        <div style={{ margin: '40px 10px', display: 'flex', justifyContent: 'center' }}>
                            <img src={imagePassport == null ? passportImage : imagePassport}
                                style={{ borderRadius: '20px', maxWidth: "-webkit-fill-available", maxHeight: "350px" }}
                            />
                        </div>

                        <div className='tips-title'>
                            -اتبع النصائح التالية:

                            <div className='tips'>
                                <div className='each-tip'>
                                    <img src={not_cropped_pp} />
                                    غير مقصوصة

                                </div>

                                <div className='each-tip'>
                                    <img src={not_blurred_pp} />
                                    ليس واضحا

                                </div>


                                <div className='each-tip'>
                                    <img src={reflections_pp} />
                                    لا انعكاسات
                                </div>
                            </div>
                        </div>

                        <label className="file-label button button-blue" style={{ 'margin': '40px auto' }}>
                            {imagePassport ? "إعادة التقاط الصورة" : "بدء تشغيل الكاميرا"}
                            <input accept="image/jpeg, image/png" className="file-input" type="file" capture="environment" style={{ "display": 'none' }} onChange={handleFileChangePassport} />
                        </label>

                    </div>
                )}


            </div>

        </div>
    )
});

export default RegistrationStepper2;
