import React, { useState, forwardRef, useImperativeHandle } from 'react';
import "../payment-verification/payment-verification.css";

import imageCompression from 'browser-image-compression';

const PaymentVerificationStepper4 = forwardRef((props, ref) => {
    const {
        setLoading,
        setLoadingProgress,
        stepper,
        setStepper,
        setStepperValidation,
    } = props;

    useImperativeHandle(ref, () => ({
        uploadReceipt
    }));

    let [imageName, setImageName] = useState('اختر الصورة');
    let [imageScr, setImageScr] = useState(null);

    const handleFileChange = async (event) => {
        const file = event.target.files[0]; // Get the selected file
        if (file) {
            // console.log('originalFile instanceof Blob', file instanceof Blob); // true
            // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

            const options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }

            try {
                setLoading(true)
                const compressedFile = await imageCompression(file, options);
                // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                const reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onloadend = () => {
                    setImageScr(reader.result);
                };
                const fileName = file.name;
                setImageName(fileName);

                setStepperValidation(prevState => ({
                    ...prevState,
                    4: true
                }));

                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
    };

    const uploadReceipt = async () => {
        setLoadingProgress(true);

        const response = await fetch(process.env.REACT_APP_API + 'uploadReceipt', {
            method: 'POST',
            body: JSON.stringify({
                receiptImage: imageScr
            }),
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData['message'] && responseData['message'] === "Receipt image uploaded successfully") {
                localStorage.setItem('status', 5);
                setStepper(stepper + 1)
                window.scrollTo(0, 0);
            }
        }

        setLoadingProgress(false);
    };

    return (
        <div className="inner-content">
            <div className="main-title form-title">المستندات</div>
            <div className="sec-title">تأكد من إدخال البيانات المطلوبة.</div>

            <form autoComplete="off" className="mb-4" noValidate>

                <label className="main-label" style={{ margin: '40px 0px 10px 0px' }}>صورة من إيصال الدفع الخاص بالبنك*</label>

                <div className="input-holder file-name">
                    <div className="main-input" style={{ width: '60%', overflow: 'hidden' }}>{imageName}</div>
                    <label className="file-label button button-blue"> إرفاق صورة
                        <input accept="image/jpeg, image/png" className="file-input" type="file" onChange={handleFileChange} />
                    </label>
                </div>

                <div className="file-cond">أنواع الملفات التي يمكن إرفاقها هي (JPG - PNG).</div>

            </form>
        </div>
    );
});

export default PaymentVerificationStepper4;
