import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{
            display: props.loading == 'true' ? 'inital' : 'none',
            position: "fixed",
            height: '100%',
            width: '100%',
            zIndex: 10000,
        }}>
            <Box sx={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: 'translate(-50%, -50%)',
                zIndex: 100000,
                background: '#ffffff',
                borderRadius: '50%',
                height: '100px'
            }}>
                <CircularProgress
                    variant="determinate"
                    value={100}
                    size={100} thickness={2.5}
                    sx={{
                        color: '#d2e9ff', // Background color
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                />
                <CircularProgress variant="determinate" {...props} size={100} thickness={2.5} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary" style={{ fontSize: 20 }}>
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

const ProgressLoading = ({ isLoading }) => {
    let [valueProgress, setValueProgress] = useState(0);

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                const random = Math.floor(Math.random() * 9) + 1;
                setValueProgress(valueProgress >= 90 ? valueProgress : valueProgress + random)
            }, 1500);
        } else {
            setValueProgress(0)
        }

    }, [valueProgress]);

    useEffect(() => {
        if (isLoading) {
            const random = Math.floor(Math.random() * 5) + 1;
            setValueProgress(random)
        }
    }, [isLoading]);




    return (
        <main>
            <CircularProgressWithLabel value={valueProgress} loading={isLoading.toString()} />
        </main>
    );
};

export default ProgressLoading;
