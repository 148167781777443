import React from 'react';

const FamilyMemberCard = ({ fullName, passportImage, nationality, age, gender, passportNumber, relationship }) => {
    const getColor = {
        "أب": '#f3d0d0',
        "أم": '#f3d0d0',
        "إبن": '#d0ecf3',
        "إبنة": '#d0ecf3',
        "أخ": '#f2f3d0',
        "أخت": '#f2f3d0',
        "زوج": '#d0f3d1',
        "زوجة": '#d0f3d1'
    };


    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg p-4 my-4" style={{ backgroundColor: getColor[relationship] }}>
            <img className="w-full h-40 object-contain" src={passportImage} alt={`${fullName}'s passport`} />
            <div className="px-6 pt-4">
                <div className="font-bold text-xl mb-2 text-center">{fullName}</div>

                <hr className="h-px my-4 bg-gray-200 border-0"></hr>

                <div className='flex flex-row flex-wrap gap-4'>
                    <p className="text-gray-700 text-sm font-bold bg-white py-1 px-2 rounded-xl">الجنسية: <span className='font-normal'>{nationality}</span></p>
                    <p className="text-gray-700 text-sm font-bold bg-white py-1 px-2 rounded-xl">العمر: <span className='font-normal'>{age}</span></p>
                    <p className="text-gray-700 text-sm font-bold bg-white py-1 px-2 rounded-xl">النوع: <span className='font-normal'>{gender}</span></p>
                    <p className="text-gray-700 text-sm font-bold bg-white py-1 px-2 rounded-xl">رقم جواز السفر: <span className='font-normal'>{passportNumber}</span></p>
                    <p className="text-gray-700 text-sm font-bold bg-white py-1 px-2 rounded-xl">صلة القرابة: <span className='font-normal'>{relationship}</span></p>
                </div>
            </div>
        </div>
    );
};

export default FamilyMemberCard;