import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./scenes/home/home";
import { Helmet } from "react-helmet";
import Login from "./scenes/login/login";
import Registration from "./scenes/registration/registration";
import Course from "./scenes/course/course";
import RegistrationSuccess from "./scenes/registration-success/success";
import PaymentVerification from "./scenes/payment-verification/payment-verification";
import Profile from "./scenes/profile/profile";
import ForgetID from "./scenes/forget-id/forget-id";
import ForgetPassword from "./scenes/forget-password/forget-password";
import CourseDetails from "./scenes/course-details/course-details";
import Thread from "./scenes/thread/thread";
function App() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="app">

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/course" element={<Course />} />
          <Route path="/registration-success" element={<RegistrationSuccess />} />
          <Route path="/payment-verification" element={<PaymentVerification />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/forget-id" element={<ForgetID />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/course-details" element={<CourseDetails />} />
          <Route path="/thread" element={<Thread />} />

        </Routes>
      </div>
    </>
  );
}

export default App;
