import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./course-details.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import profile_pic from "./../../assets/img/profile-pic.png";

import {
  faVideo,
  faMobileAlt,
  faInfinity,
  faClosedCaptioning,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import Loading from "../../components/loading";
import Header from "../../components/header";

const CourseDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState(null);

  useEffect(() => {
    if (!location.state || !location.state.course_id) {
      navigate("/");
      return;
    }

    const getCourseDetails = async () => {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API}getCourseDetails/${location.state.course_id}`,
        {
          method: "GET",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCourse(data.course);
      } else {
        toast.error("An error occurred!");
      }
      setLoading(false);
    };

    getCourseDetails();
  }, [navigate, location.state]);

  const navigateToCourseContent = () => {
    if (course.progress_percentage < 100) {
      navigate("/course", { state: { course_id: course.course_id } });
    }
  };

  return (
    <main>
      <Toaster position="bottom-center" />
      <Loading isLoading={loading} />
      <div className="course-details">
        <Header />

        {course && (
          <div className="big-row">
            <div className="content">
              <h1>{course.title}</h1>
              <p>{course.description}</p>
              <div className="course-learn">
                <h2>ما ستتعلمه</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: course.what_to_learn }}
                />
              </div>
              <div className="instructor-info">
                <h2>مقدم من: {course.instructor.name}</h2>
                {course.instructor.photo_url && (
                  <img
                    // src={course.instructor.photo_url}
                    src={profile_pic}
                    alt={course.instructor.name}
                    className="instructor-image"
                  />
                )}
                <p>{course.instructor.bio}</p>
              </div>

              {/* <div className="course-content">
                <h2 style={{margin:"30px 0px"}}>محتويات الدورة</h2>
                {course.contents.map((content, index) => (
                  <li
                    key={content.id}
                    className={`content-item ${
                      content.id < course.start_from_content.id
                        ? "finished"
                        : content.id === course.start_from_content.id
                        ? "start-from"
                        : "locked"
                    }`}
                    onClick={() =>
                      content.id >= course.start_from_content.id &&
                      navigateToCourseContent()
                    }
                    style={{
                      cursor:
                        content.id >= course.start_from_content.id
                          ? "pointer"
                          : "not-allowed",
                    }}
                  >
                    {content.type === "video" ? (
                      <>
                        <FontAwesomeIcon  style={{ marginLeft: "20px" }} icon={faVideo} />
                        {content.video_title}
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon  style={{ marginLeft: "20px" }} icon={faQuestionCircle} />
                        {content.question_text}
                      </>
                    )}
                  </li>
                ))}
              </div> */}
            </div>
            <div className="start-div">
              <h1>تتضمن هذه الكورس:</h1>
              <ul style={{ marginTop: "10px" }}>
                <li>
                  <FontAwesomeIcon
                    style={{ marginLeft: "20px" }}
                    icon={faVideo}
                  />{" "}
                  1 ساعة فيديو حسب الطلب
                </li>
                <li>
                  <FontAwesomeIcon
                    style={{ marginLeft: "20px" }}
                    icon={faMobileAlt}
                  />{" "}
                  الوصول عبر الجوال والتلفزيون
                </li>
                <li>
                  <FontAwesomeIcon
                    style={{ marginLeft: "20px" }}
                    icon={faInfinity}
                  />{" "}
                  وصول مدى الحياة
                </li>
                <li>
                  <FontAwesomeIcon
                    style={{ marginLeft: "20px" }}
                    icon={faClosedCaptioning}
                  />{" "}
                  ترجمات مغلقة
                </li>
                <li>
                  <FontAwesomeIcon
                    style={{ marginLeft: "20px" }}
                    icon={faCertificate}
                  />{" "}
                  شهادة إتمام
                </li>
              </ul>
              <button onClick={navigateToCourseContent}> {location.state.course_progress === 0 ? 'ابدأ' : 'إستكمال'} </button>
            </div>
          </div>
        )}
        <div className="copy-rights">
          © 2024 All rights reserved. ecat
          <br />
          Developed and operated by Patron Group.
        </div>
      </div>
    </main>
  );
};

export default CourseDetails;
