import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Loading from "../../components/loading";
import Header from "../../components/header";
import "./thread.css";
import { TextField, IconButton } from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import { grey } from "@mui/material/colors";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import profile_pic from "./../../assets/img/profile-pic.png";


const Thread = () => {
  const navigate = useNavigate();

  let [loading, setLoading] = useState(false);
  const [thread, setThread] = useState(null);
  const [newPostContent, setNewPostContent] = useState("");
  const [newCommentContent, setNewCommentContent] = useState("");
  const [selectedPostId, setSelectedPostId] = useState(null);

  useEffect(() => {
    const userStatus = Number(localStorage.getItem("status"));

    if (userStatus && userStatus >= 7) {
      const getThread = async () => {
        setLoading(true);

        const response = await fetch(
          process.env.REACT_APP_API + "getThread/1",
          { method: "GET" }
        );

        if (response.ok) {
          const responseData = await response.json();
          setThread(responseData);
          console.log(thread);
        }

        setLoading(false);
      };

      getThread();
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("status");
      navigate("/");
    }
  }, [navigate]);

  const handleLikePost = async (postId) => {
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}likePost/${postId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        return;
      }

      setThread({
        ...thread,
        posts: thread.posts.map((post) =>
          post.id === postId
            ? { ...post, likes: post.likes + 1, user_liked: true }
            : post
        ),
      });
    } catch (error) {
      toast.error("لقد حدث خطأ ما!");
      console.error("Error liking post:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddPost = async () => {
    console.log(newPostContent);
    setLoading(true); // Set loading to true at the start

    try {
      const response = await fetch(`${process.env.REACT_APP_API}addPost/1`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ content: newPostContent }),
      });

      if (!response.ok) {
        throw new Error("Failed to add post");
      }

      const data = await response.json();
      setThread({
        ...thread,
        posts: [...thread.posts, data],
      });
      setNewPostContent("");
    } catch (error) {
      console.error("Error adding post:", error);
      toast.error("لقد حدث خطأ ما!");
    } finally {
      setLoading(false); // Set loading to false in finally block
    }
  };

  const handleAddComment = async (postId) => {
    if (newCommentContent.trim() === "" || postId !== selectedPostId) return;
    setLoading(true); // Set loading to true at the start

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}addComment/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ content: newCommentContent }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add comment");
      }

      const data = await response.json();
      setThread({
        ...thread,
        posts: thread.posts.map((post) =>
          post.id === postId
            ? { ...post, comments: [...post.comments, data] }
            : post
        ),
      });
      setNewCommentContent("");
      setSelectedPostId(null);
    } catch (error) {
      console.error("Error adding comment:", error);
      toast.error("لقد حدث خطأ ما!");
    } finally {
      setLoading(false); // Set loading to false in finally block
    }
  };

  return (
    <main>
      <Toaster toastOptions={{ duration: 5000 }} position="bottom-center" />

      <Loading isLoading={loading} />
      <div className="thread-screen">
        <Header />
        {thread && (
          <>
            <div className="add-post">
              {/* <IoIosAdd className="icon-add" /> */}
              <TextField
                id="inputId"
                placeholder="أضف منشور جديد"
                value={newPostContent}
                onChange={(e) => setNewPostContent(e.target.value)}
                variant="outlined"
                className="add-bar"
              />

              <button
                variant="contained"
                onClick={handleAddPost}
                className="thread-add-button"
              >
                نشر
              </button>
            </div>
            <div className="thread-posts">
              {thread.posts.map((post, index) => (
                <div className="thread-post" key={index}>
                  <div className="post-user">
                    <img
                      // src={post.user.face_image}
                      src={profile_pic}
                      alt="user face" />
                    <span style={{ fontSize: "18px" }}>
                      {post.user.full_name}
                    </span>
                  </div>
                  <div className="post-content">{post.content}</div>
                  <div className="post-actions">
                    <IconButton
                      style={{ color: grey[500] }}
                      onClick={() => handleLikePost(post.id)}
                    >
                      {post.user_liked ? (
                        <ThumbUpIcon />
                      ) : (
                        <ThumbUpOutlinedIcon />
                      )}
                      <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                        {post.likes}
                      </span>
                    </IconButton>
                    <IconButton
                      style={{ color: grey[500] }}
                      onClick={() =>
                        setSelectedPostId(
                          selectedPostId === post.id ? null : post.id
                        )
                      }
                    >
                      <ModeCommentOutlinedIcon />{" "}
                      <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                        {post.comments.length}
                      </span>
                    </IconButton>
                  </div>

                  {selectedPostId === post.id && (
                    <div className="comments-section">
                      {post.comments.map((comment, commentIndex) => (
                        <div className="comment" key={commentIndex}>
                          {comment.content}
                        </div>
                      ))}
                      <div className="add-comment">
                        <TextField
                          placeholder="أضف تعليقًا"
                          value={newCommentContent}
                          onChange={(e) => setNewCommentContent(e.target.value)}
                          fullWidth
                          variant="outlined"
                          className="comment-bar"
                        />

                        <button
                          variant="contained"
                          onClick={() => handleAddComment(post.id)}
                          className="thread-add-button"
                        >
                          تعليق
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default Thread;
